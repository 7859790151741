import gql from "graphql-tag";

export const GET_SINGLE_PRODUCT = gql`
  query getSingleProduct($slug: String) {
    products(where: { slug: $slug }) {
      nodes {
        productBarcode
        productOrigin {
          name
        }
        productDetails {
          afati
          barkodi
          kodiShifra
          pesha
          productExtraInfo {
            image {
              mediaItemUrl
            }
          }
        }
        galleryImages {
          nodes {
            mediaItemUrl
          }
        }
        ... on SimpleProduct {
          image {
            id
            sourceUrl(size: LARGE)
          }
          id
          stockQuantity
          stockStatus
          productId
          name
          description
          slug
          onSale
          salePrice
          regularPrice
          productCategories(
            where: {
              hierarchical: true
              shouldOnlyIncludeConnectedItems: true
              shouldOutputInFlatList: true
              orderby: TERM_ID
            }
          ) {
            nodes {
              productCategoryId
              id
              name
            }
          }
        }
        productTags {
          nodes {
            brandPhoto {
              brandPhoto {
                sourceUrl(size: MEDIUM)
                title
              }
            }
          }
        }
      }
    }
  }
`;
