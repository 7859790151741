import React from "react";

import ContentLoader from "react-content-loader";

const ProductLoaderMobile = () => (
  <div className="ProductLoader">
    <ContentLoader 
        height={350}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
    >
        <rect x="72" y="61" rx="0" ry="0" width="153" height="93" /> 
        <rect x="226" y="13" rx="0" ry="0" width="46" height="29" /> 
        <rect x="129" y="182" rx="0" ry="0" width="41" height="23" /> 
        <rect x="61" y="221" rx="0" ry="0" width="175" height="20" /> 
        <rect x="99" y="257" rx="0" ry="0" width="100" height="20" /> 
        <rect x="56" y="290" rx="0" ry="0" width="190" height="42" />
    </ContentLoader>
  </div>
);

export default ProductLoaderMobile;
