import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../../lib/CartContext/CartContext";
import minusIcon from "../../assets/icons/minusIcon.svg";
import plusIcon from "../../assets/icons/plusIcon.svg";
import shoppingCartIcon from "../../assets/icons/shoppingCartIcon.svg";
import itemAddedIcon from "../../assets/icons/cartIconn.svg";
import CheckMarkLoader from "../CheckMarkLoader/CheckMarkLoader";
import { GlobalContext } from "../../contexts/GlobalContext";

import "./ProductCard.scss";

export const ProductCard = (props) => {
  const { product } = props;
  const globalContext = useContext(GlobalContext);
  const cartContext = useCartContext();
  const productQuantity = cartContext.getProductQuantity(product.productId);
  const [itemQuantity, setItemQuantity] = useState(
    productQuantity ? productQuantity : 1
  );
  const [loadingButton, setLoadingButton] = useState(false);
  const maxQuantity = product.stockQuantity;

  const handleAddToCart = (add, update) => {
    if (add) {
      if (itemQuantity <= maxQuantity) {
        cartContext.addProduct(product.productId, itemQuantity);
        // cartContext.addProductToLocalCart(product.productId, itemQuantity);
        setLoadingButton(true);
        setTimeout(() => {
          setLoadingButton(false);
        }, 1800);
      } else {
        globalContext.toggleQuantityModal();
      }
    } else if (update) {
      if (itemQuantity <= maxQuantity) {
        cartContext.updateProductQuantity(product.productId, itemQuantity);
        // cartContext.updateProductFromLocalCart(product.productId, itemQuantity);
        setLoadingButton(true);
        setTimeout(() => {
          setLoadingButton(false);
        }, 1800);
      } else {
        globalContext.toggleQuantityModal();
      }
    }
  };

  const checkProduct = (productId) => {
    const product = cartContext?.items?.find((item) => {
      return item?.product?.id === productId;
    });
    return product;
  };

  const handleQuantityChange = (increment = true) => {
    if (increment && itemQuantity !== maxQuantity) {
      setItemQuantity((prev) => prev + 1);
    }

    if (!increment && itemQuantity !== 1) {
      setItemQuantity((prev) => prev - 1);
    }
  };

  return (
    <article
      className={
        props.productFocus ? "ProductCard product-focus" : "ProductCard"
      }
    >
      {product.stockStatus === "OUT_OF_STOCK" && (
        <div className="out-of-stock-wrapper">
          <div className="button">E SHITUR</div>
        </div>
      )}
      <div className="image-container  justify-content-center align-items-center">
        {product.productOrigin && (
          <div className="origin_flag">
            <img
              className="origin_flag"
              src={product.productOrigin.flag}
              alt=""
            />
          </div>
        )}
        <Link
          to={"/produkti/" + product.slug}
          className="name-details d-flex flex-center flex-image-wrap"
        >
          <img
            className="product_image"
            src={product.image?.sourceUrl}
            alt={product.name}
          />
        </Link>
      </div>
      <div className="details-container">
        <div className="top d-flex justify-content-between align-bottom flex-grow-1">
          <Link to={"/produkti/" + product.slug} className="name-details">
            <span className="name">{product.name.replace("&amp;", "&")}</span>
          </Link>

          {!product.salePrice && (
            <div className="price">
              <span className="regular-price">
                {product.regularPrice?.replace("&nbsp;", "")}
              </span>
            </div>
          )}

          {product.salePrice && (
            <div className="price">
              <div className="discount-price">
                <p>{product.regularPrice.replace("&nbsp;", "")}</p>
              </div>
              <span className="regular-price">
                {product.salePrice.replace("&nbsp;", "")}
              </span>
            </div>
          )}
        </div>
        <div className="product-buttons mt-10 ">
          <div className="quantity-buttons-container">
            <div className="quantity-input">
              <span>
                <img
                  onClick={() => handleQuantityChange(false)}
                  className="minus-icon"
                  src={minusIcon}
                  alt=""
                />
                <input
                  id="quantity"
                  min="1"
                  type="number"
                  value={itemQuantity}
                  max={2}
                  onChange={(e) => setItemQuantity(e.target.value)}
                />
                <img
                  onClick={() => handleQuantityChange()}
                  className="plus-icon"
                  src={plusIcon}
                  alt=""
                />
              </span>
            </div>

            {checkProduct(product.id) !== undefined ? (
              <div
                className="order-button"
                onClick={() => handleAddToCart(false, true)}
              >
                {loadingButton ? (
                  <CheckMarkLoader />
                ) : (
                  <img src={itemAddedIcon} alt="" />
                )}
              </div>
            ) : (
              <div
                className="order-button"
                onClick={() => handleAddToCart(true, false)}
              >
                {loadingButton ? (
                  <CheckMarkLoader />
                ) : (
                  <img src={shoppingCartIcon} alt="" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
};
