const cartReducer = (state = [], action) => {
  switch (action.type) {
    case "INIT_CART":
      return action.payload;
    case "ADD_ITEMS_TO_CART":
      return action.payload;
    case "ADD_TO_CART":
      if (state.includes(action.payload)) {
        return state;
      }
      return [...state, action.payload];
    case "REMOVE_ITEM":
      if (state.length === 1) {
        return [];
      }
      return state.filter(item => item !== action.payload);
    case "EMPTY_CART":
      return [];
    default:
      return state;
  }
};

export default cartReducer;
