import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { CartContext } from "./CartContext";
import { GET_CART } from "../../../src/queries/cartQuery";
import { EMPTY_CART } from "../../queries/mutations/cartMutations";

import {
  UPDATE_ITEM_QUANTITIES,
  ADD_ITEM_TO_CART,
} from "../../queries/mutations/cartMutations";

export const CartContextProvider = (props) => {
  let [cart, setCart] = useState([]);

  let localCart = localStorage.getItem("cart");

  useEffect(() => {
    localCart = JSON.parse(localCart);
    if (localCart) {
      setCart(localCart);
    }
  }, []);
  const {
    loading,
    error,
    data: cartData,
    refetch: refetchCart,
    fetchMore: fetchMoreItems,
  } = useQuery(GET_CART);

  const [
    updateItemQuantities,
    { loading: updateLoading, error: updateErrors },
  ] = useMutation(UPDATE_ITEM_QUANTITIES);

  const [
    emptyCartMutation,
    { loading: emptyLoading, error: emptyError },
  ] = useMutation(EMPTY_CART);

  if (emptyError) {
    console.log("error empty ");
  }

  const [addItem, { loading: addLoading, error: addErrors }] = useMutation(
    ADD_ITEM_TO_CART
  );

  const getCartKeyByProductId = (productId) => {
    const cartKey = cartData?.cart?.contents.nodes.find((item) => {
      return item.product.productId === productId;
    });
    return cartKey.key;
  };

  const getProductQuantity = (productId) => {
    const product = cartData?.cart.contents.nodes.find((item) => {
      return item?.product.productId === productId;
    });
    return product?.quantity;
  };

  const updateProductFromCart = async (cartKey, quantity) => {
    await updateItemQuantities({
      variables: {
        input: {
          clientMutationId: "cart-quantity-update",
          items: [{ key: cartKey, quantity: quantity }],
        },
      },

      update(cache, { data: { updateItemQuantities: updateData } }) {
        const { cart } = cache.readQuery({ query: GET_CART });
        // const newContents = updateData.cart.contents;
        const newItems =
          quantity === 0
            ? cart.contents.nodes.filter((item) => {
                return item.key !== updateData.items[0].key;
              })
            : cart.contents.nodes.map((item) => {
                return {
                  ...item,
                  quantity:
                    item.key === updateData.items[0].key
                      ? updateData.items[0].quantity
                      : item.quantity,
                };
              });

        const newValues = {
          ...cart,
          contents: {
            ...updateData.cart.contents,
            nodes: newItems,
          },
          isEmpty: updateData.cart.isEmpty,
          subtotal: updateData.cart.subtotal,
          total: updateData.cart.total,
        };

        cache.writeQuery({
          query: GET_CART,
          data: { cart: newValues },
        });
      },
    });
  };

  const updateProductQuantity = async (
    productID,
    quantity,
    cartKey = undefined
  ) => {
    const cartKeyOfProduct = cartKey
      ? cartKey
      : getCartKeyByProductId(productID);

    if (!cartKeyOfProduct) {
      return;
    }

    await updateItemQuantities({
      variables: {
        input: {
          clientMutationId: "cart-quantity-update",
          items: [{ key: cartKeyOfProduct, quantity: quantity }],
        },
      },
      update(cache, { data: { updateItemQuantities: updateData } }) {
        const { cart } = cache.readQuery({ query: GET_CART });

        const newItems =
          quantity === 0
            ? cart.contents.nodes.filter((item) => {
                return item.key !== updateData.items[0].key;
              })
            : cart.contents.nodes.map((item) => {
                return {
                  ...item,
                  quantity:
                    item.key === updateData.items[0].key
                      ? updateData.items[0].quantity
                      : item.quantity,
                };
              });

        const newValues = {
          ...cart,
          contents: {
            ...cart.contents,
            nodes: newItems,
          },
          isEmpty: updateData.cart.isEmpty,
          subtotal: updateData.cart.subtotal,
          total: updateData.cart.total,
        };

        cache.writeQuery({
          query: GET_CART,
          data: { cart: newValues },
        });
      },
    });
  };

  const addProduct = async (productID, quantity, variationID) => {
    try {
      await addItem({
        variables: {
          clientMutationId: "add-product-to-cart",
          productId: productID,
          quantity,
          variationId: undefined,
        },
        update(
          cache,
          {
            data: {
              addToCart: { cart },
            },
          }
        ) {
          cache.writeQuery({
            query: GET_CART,
            data: { cart },
          });
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoadMore = () => {
    fetchMoreItems({
      variables: {
        after: cartData?.cart.contents.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult: { cart } }) => {
        const newNodes = cart.contents.nodes;

        return newNodes.length
          ? {
              cart: {
                ...previousResult.cart,
                contents: {
                  ...previousResult.cart.contents,
                  nodes: [...previousResult.cart.contents.nodes, ...newNodes],
                  pageInfo: cart.contents.pageInfo,
                },
              },
            }
          : previousResult;
      },
    });
  };

  const getProductPrices = (product) => {
    const variations = product.variations?.nodes[0];
    const hasVariations = product.type === "VARIABLE" && variations;

    return {
      sale: hasVariations ? variations?.salePrice : product.salePrice,
      regular: hasVariations ? variations?.regularPrice : product.regularPrice,
    };
  };

  const emptyCart = () => {
    try {
      emptyCartMutation();
    } catch (error) {
      console.error(error);
    }
  };

  const context = {
    loading: loading,
    updateLoading,
    addLoading,
    addProduct,
    emptyCart,
    getProductPrices,
    localCart: cart,
    refetch: refetchCart,
    handleLoadMore,
    updateProductQuantity,
    updateProductFromCart,
    getProductQuantity,
    total: cartData?.cart.total,
    isEmpty: cartData?.cart.isEmpty,
    subtotal: cartData?.cart.subtotal,
    items: cartData?.cart.contents.nodes,
    cartData: cartData,
    cart: cartData?.cart.contents,
    error: error?.toString() || updateErrors?.toString() || addErrors?.toString() // prettier-ignore
  };

  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};
