import gql from "graphql-tag";

export const UPDATE_ITEM_QUANTITIES = gql`
  mutation($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      cart {
        isEmpty
        subtotal
        total

        contents(first: 100) {
          pageInfo {
            hasNextPage
            endCursor
          }
          itemCount
        }
      }
      items {
        key
        quantity
        product {
          id
          slug
          productId
          name
          onSale
          type
          image {
            id
            sourceUrl(size: MEDIUM_LARGE)
          }
          ... on SimpleProduct {
            id
            name
            salePrice
            regularPrice
            stockQuantity
          }
        }
      }
    }
  }
`;

export const EMPTY_CART = gql`
  mutation EmptyCart {
    emptyCart(input: { clientMutationId: "EmptyCart" }) {
      clientMutationId
    }
  }
`;

export const ADD_ITEM_TO_CART = gql`
  mutation addProduct(
    $clientMutationId: String!
    $productId: Int!
    $quantity: Int
  ) {
    addToCart(
      input: {
        clientMutationId: $clientMutationId
        productId: $productId
        quantity: $quantity
      }
    ) {
      cart {
        isEmpty
        subtotal
        total
        shippingTotal
        subtotalTax
        subtotal
        total
        feeTotal
        discountTotal
        contents(first: 100) {
          pageInfo {
            hasNextPage
            endCursor
          }
          itemCount
          nodes {
            key
            quantity
            product {
              id
              slug
              description
              productId
              name
              onSale
              type
              image {
                id
                sourceUrl(size: MEDIUM_LARGE)
              }
              ... on SimpleProduct {
                id
                price
                name
                salePrice
                regularPrice
                stockQuantity
              }
            }
            total
            subtotal
            subtotalTax
          }
        }
      }
    }
  }
`;
