import { useState } from "react";

const useStockModal = () => {
  const [showQuantityModal, setShowQuantityModal] = useState(false);

  function toggleQuantityModal() {
    setShowQuantityModal(!showQuantityModal);
  }

  function closeQuantityModal() {
    setShowQuantityModal(false);
  }

  return {
    showQuantityModal,
    toggleQuantityModal,
    closeQuantityModal,
  };
};

export default useStockModal;
