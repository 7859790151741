import React from "react";
import { Container } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import "./Footer.scss";
import { Link } from "react-router-dom";
import gql from "graphql-tag";

export const GET_FOOTER_DATA = gql`
  query getFooterData {
    themeOptions {
      general {
        email
        phone
        paymentMethodImage {
          mediaItemUrl
        }
      }
    }
  }
`;

const Footer = (props) => {
  const { data, error, loading } = useQuery(GET_FOOTER_DATA);

  if (loading) {
    return <div> </div>;
  }

  if (error) {
    return <div> error </div>;
  }

  const paymentImage =
    data.themeOptions.general.paymentMethodImage.mediaItemUrl;
  const date = new Date(),
    currentYear = date.getFullYear();

  return (
    <footer id="Footer" className="Footer">
      <Container>
        <div className="Footer__divider" />
        <div className="Footer__payments d-flex flex-column justify-content-center align-items-center">
          <p>Mënyrat e pagesës</p>
          <img src={paymentImage} alt="Payment method" height="35" />
        </div>
        <div className="Footer__links d-flex flex-row justify-content-center align-items-center">
          <div className="d-flex">
            <Link
              className="register-button d-flex align-items-center mr-20"
              to="/informacione/kushtet-e-perdorimit"
            >
              Termet dhe Kushtet
            </Link>
          </div>
          <div className="d-flex">
            <Link
              className="register-button d-flex align-items-center ml-20"
              to="/informacione/kushtet-e-privatesise"
            >
              Politikat e Privatesis
            </Link>
          </div>
        </div>
        <div className="Footer__bottom d-flex justify-content-center align-items-center">
          <p className="Footer__copyright">Copyright © Begmart {currentYear}</p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
