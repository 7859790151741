import React, { useState, useEffect } from "react";
import { ProductsContext } from "./ProductsContext";
import { useQuery } from "@apollo/react-hooks";
import { GET_FILTERED_PRODUCTS } from "../../queries/productsQuery";

import { useHistory } from "react-router-dom";

export const ProductsContextProvider = (props) => {
  const history = useHistory();
  const [activeCategoryId, setActiveCategoryId] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [brandPath, setBrandPath] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState();

  const {
    loading: loadingProducts,
    error: errorLoadingProducts,
    data: productsData,
    fetchMore: fetchMoreProducts,
    refetch: refetchProducts,
  } = useQuery(GET_FILTERED_PRODUCTS, {
    variables: {
      after: "",
      categoryId: selectedCategoryId,
      category: selectedSubCategoryId,
      brandSlug: brandPath,
      limit: 20,
    },
  });

  if (errorLoadingProducts) {
    // console.log("error");
  }

  if (loadingProducts) {
    // console.log("loading products");
  }

  const changeActiveCategory = () => {
    setActiveCategoryId(9999);
  };

  const loadMore = () => {
    fetchMoreProducts({
      variables: {
        after: productsData.products.pageInfo.endCursor,
        limit: 10,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;
        return newNodes.length
          ? {
              products: {
                __typename: previousResult.products.__typename,
                nodes: [...previousResult.products.nodes, ...newNodes],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  const filterAllProducts = () => {
    setSelectedCategoryId(0);
    setActiveCategoryId(0);
    setBrandPath(undefined);
    setSelectedSubCategoryId("");
    history.push("/kategoria/");
  };

  const filterProductsWithCategory = (id, categoryName) => {
    setActiveCategoryId(id);
    setSelectedSubCategoryId("");
    history.push(`/kategoria/${categoryName}`);

    setSelectedCategoryId(id);
  };

  const handleCategoryChange = (id, categoryName) => {
    setActiveCategoryId(id);
    setSelectedCategoryName(categoryName);
  };

  // Handle change category and refetch cart
  const handleSubCategoryChange = (slug) => {
    setSelectedCategoryId(0);
    setActiveCategoryId("");
    setSelectedSubCategoryId(slug);
    history.push(`/kategoria/${slug}`);

    refetchProducts({
      variables: {
        after: "",
        category: selectedSubCategoryId,
        limit: 12,
        refetchStrategy: "network-only",
      },
    });
  };

  const changeSubCategoryPath = (value) => {
    setBrandPath(undefined);
    setSelectedSubCategoryId(value);
  };

  const changeBrandPath = (value) => {
    setSelectedSubCategoryId(undefined);
    setBrandPath(value);
  };

  const context = {
    selectedCategoryId,
    handleCategoryChange,
    handleSubCategoryChange,
    selectedCategoryName,
    selectedSubCategoryId,
    loadMore,
    productsData,
    filterAllProducts,
    activeCategoryId,
    changeSubCategoryPath,
    changeBrandPath,
    filterProductsWithCategory,
    changeActiveCategory,
  };

  return (
    <ProductsContext.Provider value={context}>
      {props.children}
    </ProductsContext.Provider>
  );
};
