import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import LoginIcon from "../../assets/icons/login-icon-red.svg";
import LoginIconMobile from "../../assets/icons/signInIcon-m.svg";
import SignUpIconMobile from "../../assets/icons/signUpIcon_m.svg";
import RegisterIcon from "../../assets/icons/register-icon-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { useResponseDimensions } from "../../lib/hooks/useResponseDimensions";
import { GET_HOME_LOGO, GET_MENUS } from "../../queries/headerQuery";

import CategoriesMobile from "../../components/Header/CategoriesMobile/CategoriesMobile";

import "./Header.scss";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const { data, error, loading } = useQuery(GET_MENUS);
  const isTablet = useResponseDimensions().isTablet;
  const isIphoneLandscape = useResponseDimensions().isIphoneLandscape;
  const dispatch = useDispatch(),
    searchValue = useSelector((state) => state.uiState.searchValue),
    [hasError, setError] = useState(false),
    [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (searchValue && searchValue !== "") {
      setSearchInput(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchInput && searchInput.length > 2) {
      setError(false);
    }
  }, [searchInput]);

  const { data: headerData, loading: loadingLogo, error: errorLogo } = useQuery(
    GET_HOME_LOGO
  );

  if (loadingLogo) {
    return <div> </div>;
  }

  if (errorLogo) {
    return <div> {errorLogo.message}</div>;
  }

  const homeLogo = headerData.themeOptions.homePageSlider.logo?.mediaItemUrl;
  const email = headerData.themeOptions.general.email;
  const phone = headerData.themeOptions.general.phone;

  const handleNavClick = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle("menu-mobile");

    // const App = document.getElementById("App-container");
    // App.classList.toggle("menu-mobile");
  };

  let menuItems = [];
  if (!loading && !error && data) {
    menuItems = data.menus.nodes[0].menuItems.nodes;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchInput && searchInput.length <= 2) {
      setError(true);
      return;
    }

    // dispatch(closeNav());
    props.history.push("/kerko?s=" + searchInput);
  };

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <header className={classNames("Header", isOpen && "nav-open")}>
      <section className="Header__top-header">
        <div className="container d-flex justify-content-end">
          <span>
            <a href="mailto:info@kosmontefoods.com">{email}</a> &#8231;
            <a href="tel:038 000 000">{phone}</a>
          </span>
        </div>
      </section>
      <section className="Header__main-header">
        <div
          className={
            isIphoneLandscape
              ? "Header__items-container "
              : "Header__items-container container"
          }
        >
          <button
            className={classNames(
              "nav-toggler",
              "show-sm",
              isOpen && "nav-open"
            )}
            onClick={handleNavClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="col-6 col-md-3  col-sm-6 col-lg-2 logo ">
            <Link to="/">
              <img className="logo-image" src={homeLogo} alt="Logo" />
            </Link>
          </div>
          {isIphoneLandscape && !userInfo.isLogged ? (
            <div className="auth-icons-mobile">
              <Link className="link" to="/kyçu">
                Kyçu
                <img className="signIn-Icon" src={LoginIconMobile} alt="" />
              </Link>

              <Link className="link" to="/regjistrohu">
                <img className="signUp-Icon" src={SignUpIconMobile} alt="" />
              </Link>
            </div>
          ) : (
            <div className="logged-in-container">
              {isTablet && (
                <div>
                  <Link className="logged-in-mobile" to="/llogaria">
                    <img src={LoginIcon} alt="Logo" width={20} height={20} />
                    {userInfo.firstName} {userInfo.lastName}
                  </Link>
                </div>
              )}
            </div>
          )}
          <div className="col-5 hidden-sm">
            <form className="search-container-desktop" onSubmit={handleSubmit}>
              <input
                value={searchInput}
                onChange={handleChange}
                className="form-control search-input w-100"
                type="text"
                placeholder="Kërko produkte"
              />
              <button className="icon-button"> s </button>
            </form>
          </div>
          <div
            className={classNames(
              "d-flex",
              "main-navigation",
              isOpen && "nav-open"
            )}
          >
            <ul className="navbar-nav ml-auto d-flex flex-row">
              {isTablet && (
                <CategoriesMobile
                  isOpen={(value) => {
                    document.body.classList.toggle("menu-mobile");
                    // const App = document.getElementById("App-container");
                    // App.classList.toggle("menu-mobile");
                    setIsOpen(value);
                  }}
                />
              )}
              <div className="Header__menu-items">
                {menuItems.map((item, key) => {
                  return (
                    <li key={key} className="nav-item active">
                      <Link
                        onClick={() => setIsOpen(!isOpen)}
                        className="nav-link"
                        to={"/informacione/" + item.connectedObject.slug}
                      >
                        {item.label}
                      </Link>
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
          <div className="Header__buttons hidden-sm">
            {userInfo.isLogged && userInfo.lastName ? (
              <Link
                className="login-button d-flex align-items-center"
                to="/llogaria"
              >
                <img src={LoginIcon} alt="Logo" width={20} height={20} />
                {userInfo.firstName} {userInfo.lastName}
              </Link>
            ) : (
              <>
                <Link
                  className="login-button d-flex align-items-center"
                  to="/kyçu"
                >
                  <img src={LoginIcon} alt="Logo" width={20} height={20} />
                  Kyçu
                </Link>
                <Link
                  className="register-button d-flex align-items-center ml-20"
                  to="/regjistrohu"
                >
                  <img src={RegisterIcon} alt="Logo" width={20} height={20} />
                  Regjistrohu
                </Link>
              </>
            )}
          </div>
        </div>

        <form className="search-container-mobile" onSubmit={handleSubmit}>
          <input
            value={searchInput}
            onChange={handleChange}
            className="form-control search-input w-100"
            type="text"
            placeholder="Kërko produkte"
          />
          <button className="icon-button"> s </button>
        </form>
      </section>
    </header>
  );
};

export default withRouter(Header);
