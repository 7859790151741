import gql from "graphql-tag";

export const REGISTER_MUTATION = gql`
  mutation register(
    $firstname: String
    $lastname: String
    $company: String
    $municipality: String
    $address: String
    $password: String
    $telephone: String
    $email: String!
    $captcha: String!
    $country: CountriesEnum
  ) {
    registerCustomer(
      input: {
        clientMutationId: "uniqueId"
        username: $email
        firstName: $firstname
        lastName: $lastname
        email: $email
        shippingSameAsBilling: true
        password: $password
        captcha: $captcha
        billing: {
          firstName: $firstname
          lastName: $lastname
          country: $country
          address1: $address
          company: $company
          city: $municipality
          phone: $telephone
        }
      }
    ) {
      customer {
        id
        firstName
        customerId
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginUser(
    $username: String!
    $password: String!
    $captcha: String!
  ) {
    login(
      input: {
        clientMutationId: "uniqueId"
        username: $username
        password: $password
        captcha: $captcha
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        firstName
        lastName
        jwtAuthToken
        userId
      }
    }
  }
`;

