import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation LoginUser(
    $username: String!
    $password: String!
    $captcha: String!
  ) {
    login(
      input: {
        clientMutationId: "loginFormID"
        username: $username
        password: $password 
        captcha: $captcha
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        firstName
        lastName
        jwtAuthToken
        userId
      }
    }
  }
`;
