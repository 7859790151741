import React, { createContext, Component } from "react";
import { ApolloConsumer } from "react-apollo";

export const GlobalContext = createContext();

class GlobalContextProvider extends Component {
  state = {
    categoryId: null,
    brandId: null,
    brandSelected: null,
    brandsRef: null,
    categoryStatus: false,
    quantityModal: false,
  };

  toggleQuantityModal = async () => {
    this.setState({quantityModal:!this.quantityModal})
  }

  closeQuantityModal = async () => {
    this.setState({quantityModal:false})
  }

  updateBrandSelected = async (value) => {
    this.setState({ brandSelected: value });
  };

  updateBrand = async (brand) => {
    if (brand) {
      this.setState({ brandSelected: brand });
    }
  };

  updateBrandsRef = async (ref) => {
    this.setState({ brandsRef: ref });
  };

  handleOutsideCategoriesClick = async (value) => {
    this.setState({categoryStatus: value})
  }

  render() {
    return (
      <ApolloConsumer>
        {(client) => (
          <GlobalContext.Provider
            value={{
              ...this.state,
              updateBrand: this.updateBrand,
              updateBrandsRef: this.updateBrandsRef,
              toggleQuantityModal: this.toggleQuantityModal,
              closeQuantityModal: this.closeQuantityModal,
              updateBrandSelected: this.updateBrandSelected,
              handleOutsideCategoriesClick: this.handleOutsideCategoriesClick
            }}
          >
            {this.props.children}
          </GlobalContext.Provider>
        )}
      </ApolloConsumer>
    );
  }
}
export default GlobalContextProvider;
