import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import { FormGroup, Input } from "reactstrap";

import "./DeliveryDetails.scss";
import { getNextDay } from "../../../lib/Helpers/getNextDay";

function addDays(date, days) {
  if (days === 0) return date;

  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const timeSlots = {
  "slot-1": ["8:00", "11:00"],
  "slot-2": ["15:00", "18:00"],
};

function getActiveDay() {
  const current = new Date(),
    currentHour = current.getHours(),
    currentMinutes = current.getMinutes();

  if (currentHour >= 23 && currentMinutes >= 30) {
    return addDays(current, 1);
  }

  return current;
}

function activeDaySlots() {
  const currentDay = new Date();

  return Object.values(timeSlots).filter((item) => {
    const hourDiff = parseInt(item[0].split(":")[0]) - currentDay.getHours();

    return hourDiff >= 1;
  });
}

function getSlots(date) {
  const currentDate = new Date();

  // Check if it's too late for today
  if (currentDate.getHours() >= 23 && currentDate.getMinutes() >= 30) {
    return timeSlots;
  }

  if (currentDate.getDate() !== date.getDate()) {
    return timeSlots;
  }

  return activeDaySlots();
}

const DeliveryData = (props) => {
  const { city } = props;
  const activeDay = getActiveDay();
  const [attributes, setAttributes] = useState({
      selectedDate: "",
      activeSlots: timeSlots,
    }),
    [transportValue, setTransportValue] = useState({
      transportDate: "",
      transportTime: "",
      comment: props.comment,
    });

  useEffect(() => {
    const { transportDate, transportTime, comment } = transportValue;
    if (transportDate && transportTime) {
      props.onDeliveryTimeChange(
        transportTime !== "0" ? transportDate + " " + transportTime : ""
      );
    }
  }, [transportValue]);

  useEffect(() => {
    props.onCommentChange(transportValue.comment);
  }, [transportValue.comment]);

  const getMinDate = () => {
    const current = new Date();
    return addDays(current, 1);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 6;
  };

  const disableSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  return (
    <div className="DeliveryDetails d-flex flex-column">
      <div className="delivery-time d-flex flex-row">
        {city === "Prishtinë" || city === "Fushë Kosovë" ? (
          <DatePicker
            selected={attributes.selectedDate}
            dateFormat="dd/MM/yyyy"
            required
            minDate={getMinDate()}
            filterDate={disableSunday}
            // maxDate={addDays(activeDay, 7)}
            onChange={(date) => {
              const dateFormat =
                date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear();

              setAttributes({
                selectedDate: date,
                activeSlots: getSlots(date),
              });

              setTransportValue({
                transportDate: dateFormat,
                transportTime: "",
              });
            }}
            placeholderText="Cakto ditën e dërgeses"
          />
        ) : (
          <DatePicker
            selected={attributes.selectedDate}
            dateFormat="dd/MM/yyyy"
            minDate={getMinDate()}
            filterDate={isWeekday}
            required
            onChange={(date) => {
              const dateFormat =
                date.getDate() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getFullYear();

              setAttributes({
                selectedDate: date,
                activeSlots: getSlots(date),
              });

              setTransportValue({
                transportDate: dateFormat,
                transportTime: "",
              });
            }}
            placeholderText="Cakto ditën e dërgeses"
          />
        )}
        <FormGroup className="ml-15">
          <select
            required
            className="h-100"
            name="select"
            id="timeSelect"
            value={transportValue.transportTime}
            onChange={(e) => {
              const { value } = e.target;
              setTransportValue({
                ...transportValue,
                transportTime: value ? value : "",
              });
            }}
          >
            <option value="">Cakto kohën</option>
            {Object.values(attributes.activeSlots).map((item) => {
              const value = item[0] + " - " + item[1];
              return (
                <option key={"time_" + item} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </FormGroup>
      </div>
      <div className="d-flex mt-15">
        <FormGroup>
          <Input
            type="textarea"
            name="comment"
            id="comment"
            cols={80}
            rows={6}
            placeholder={"Komenti"}
            value={transportValue.comment}
            onChange={(e) => {
              const { value } = e.target;
              setTransportValue({
                ...transportValue,
                comment: value ? value : "",
              });
            }}
          ></Input>
        </FormGroup>
      </div>
    </div>
  );
};

export default DeliveryData;
