import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";

import { useProductsContext } from "../../../contexts/ProductsContext/ProductsContext";
import Slider from "react-slick";
import prevArrow from "../../../assets/icons/left-cat-arrow.svg";
import nextArrow from "../../../assets/icons/right-cat-arrow.svg";
import { GET_PRODUCTS_MENU } from "../../../queries/categoriesQuery";

import Container from "reactstrap/es/Container";

import "./Categories.scss";

export const Categories = (props) => {
  const [sliderStatus, setSliderStatus] = useState("started");
  const [path, setPath] = useState("");
  const { data, error, loading } = useQuery(GET_PRODUCTS_MENU);
  const ProductsCTX = useProductsContext();
  const history = useHistory();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      ProductsCTX.changeActiveCategory();
    }
  }, []);

  useEffect(() => {
    history.listen((location) => {
      setPath(location.pathname);
    });
  });

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (loading) {
    return (
      <div className="Categories d-flex flex-row align-items-center"></div>
    );
  }

  if (error) {
    return (
      <div className="Categories d-flex flex-row align-items-center">
        Error!
      </div>
    );
  }

  if (!data.menus) {
    return (
      <div className="Categories d-flex flex-row align-items-center">
        Nuk ka kategori!
      </div>
    );
  }

  const prevArrowIcon = <img id="arrow_cat" src={prevArrow} />;
  const nextArrowIcon = <img id="arrow_cat" src={nextArrow} />;
  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToScroll: 1,
    slidesToShow: 5,
    swipe: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 3,
          swipe: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
          swipe: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: true,
        },
      },
    ],
    variableWidth: true,
    nextArrow: nextArrowIcon,
    prevArrow: prevArrowIcon,
    afterChange: (index) => {
      if (index > 0) {
        setSliderStatus("slided");
        document.getElementById("cat-slider").classList.add("slided");
      }
      if (index == 0) {
        setSliderStatus("started");
        document.getElementById("cat-slider").classList.add("started");
        document.getElementById("cat-slider").classList.remove("finished");
        document.getElementById("cat-slider").classList.remove("slided");
      }
      if (index === menuItems.length) {
        document.getElementById("cat-slider").classList.add("finished");
        document.getElementById("cat-slider").classList.remove("started");
      }
    },
  };
  const menuItems = data.menus.nodes[0].menuItems.nodes;

  const subItems = menuItems
    .filter((item) => {
      return item.childItems.nodes.length > 0;
    })
    .map((item) => {
      return {
        id: item.connectedObject.productCategoryId,
        list: item.childItems.nodes,
      };
    });

  const handleCategoryClick = (id, categoryName) => {
    const subCategories = subItems.filter((item) => {
      return item.id === id;
    });

    if (subCategories.length > 0) {
      ProductsCTX.handleCategoryChange(id);
    } else {
      ProductsCTX.filterProductsWithCategory(id, categoryName);
    }
  };

  const handleSubCategoryClick = (slug) => {
    ProductsCTX.handleSubCategoryChange(slug);
    history.push(`/kategoria/${slug}`);
  };

  const handleCategoryAll = () => {
    ProductsCTX.filterAllProducts();
  };

  if (history.location.pathname.includes("informacione")) {
    return null;
  }

  return (
    <>
      <div
        className="Categories"
        id="cat-slider"
        data-active-category={ProductsCTX.activeCategoryId}
      >
        <span
          className={
            sliderStatus === "started" ? "Categories__right-gradient" : ""
          }
        />
        <span
          className={
            sliderStatus === "slided" || sliderStatus === "finished"
              ? "Categories__left-gradient"
              : ""
          }
        />

        <Container>
          <Slider {...settings}>
            <div
              onClick={() => handleCategoryAll()}
              className="Categories__slider"
            >
              <button
                className={
                  ProductsCTX.activeCategoryId === 0
                    ? "Categories__category-item active"
                    : "Categories__category-item"
                }
              >
                <span>Të gjitha</span>
              </button>
            </div>
            {menuItems.map((category) => {
              let categoryId = category.connectedObject.productCategoryId;
              let categoryName = category.label;

              const url = category.connectedObject.image?.mediaItemUrl;

              const urlOnActive =
                category.connectedObject.categoryHoverImage?.categoryHoverImage
                  ?.mediaItemUrl;
              return (
                <div
                  key={`${categoryId}-main-menu-listing`}
                  className="Categories__slider"
                >
                  <button
                    // onMouseDown={() => handleCategoryClick(0)}
                    onClick={() =>
                      handleCategoryClick(categoryId, categoryName)
                    }
                    className={
                      ProductsCTX.activeCategoryId === categoryId
                        ? "Categories__category-item active"
                        : "Categories__category-item"
                    }
                  >
                    {category.connectedObject && (
                      <div className="item">
                        <img className="icon" src={urlOnActive} alt="" />
                        <img
                          className="iconOnActive"
                          src={urlOnActive}
                          alt=""
                        />
                        <span className="">
                          {category.label.replace("&amp;", "&")}
                        </span>
                      </div>
                    )}
                  </button>
                </div>
              );
            })}
          </Slider>
        </Container>

        <Container>
          <div className="Categories__sub-cat-container">
            {!props.recipes &&
              subItems.map((item) => {
                return (
                  <ul
                    id="sub-categories"
                    className={`Categories__sub-categories ${
                      ProductsCTX.activeCategoryId === item.id ? "active" : ""
                    }`}
                  >
                    {item.list.map((item) => {
                      const slug = item.connectedObject.slug;
                      return (
                        <Link
                          to={`/kategoria/${slug}`}
                          className="sub-category-link"
                          onClick={() => handleSubCategoryClick(slug)}
                        >
                          {item.label}
                        </Link>
                      );
                    })}
                  </ul>
                );
              })}
          </div>
        </Container>
      </div>
    </>
  );
};
