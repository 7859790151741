import React from "react";
import deleteIcon from "../../../assets/images/delete-icon.svg";

import "./RemoveFavoriteButton.scss";
import { removeFavorite } from "../../../assets/Helpers";

const RemoveFavoriteButton = props => {
  const handleFavoriteItem = e => {
    removeFavorite(props.id);
    props.onClick(props.id);
  };

  return (
    <div className="RemoveFavoriteButton">
      <button onClick={e => handleFavoriteItem(e)}>
        <img src={deleteIcon} alt="Shto në Listë" />
      </button>
    </div>
  );
};

export default RemoveFavoriteButton;
