import React from "react";

import "./LoadMoreButton.scss";

const LoadMoreButton = (props) => {
  return (
    <div className="LoadMoreButton">
      <button className="btn btn-outline" onClick={() => props.loadMore()}>
        {props.title}
      </button>
    </div>
  );
};

export default LoadMoreButton;
