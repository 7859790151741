import React from "react";
import { Link } from "react-router-dom";
import "./LatestRecipes.scss";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const RECIPES = gql`
  query Recipes($limit: Int) {
    posts(first: $limit) {
      nodes {
        slug
        title
        id
        featuredImage {
          sourceUrl
          id
        }
      }
    }
  }
`;

const LatestRecipes = () => {
  const { data, loading, error } = useQuery(RECIPES, {
    variables: {
      limit: 4,
    },
  });

  if (loading) {
    return <div> </div>;
  }

  if (error) {
    return (
      <div>
        <p> {error.message}</p>
      </div>
    );
  }

  return (
    <div className="LatestRecipes_container">
      <p className="Latest_recipes_title">Recetat e fundit</p>
      <div className="line"></div>
      {data.posts.nodes.map((item) => {
        return (
          <div className="Recipes_Card">
            <Link to={`/recetat/${item.slug}`} className="link" key={item.slug}>
              <div className="image-container">
                <img src={item.featuredImage.sourceUrl} alt="" />
              </div>
              <div className="content-box">
                <p className="recipe_title">{item.title}</p>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default LatestRecipes;
