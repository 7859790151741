import gql from "graphql-tag";

export const RECOMMENDED_PRODUCTS = gql`
  query getRecommandedProducts($tag: String, $limit: Int, $after: String) {
    products(
      where: { categoryIdIn: [1253], tag: $tag }
      first: $limit
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          stockStatus
          stockQuantity
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
        }
      }
    }
  }
`;
