import React from "react";
import FormModal from "../../components/FormModal/FormModal";
import Logo from "../../components/TopHeader/Logo/Logo";
import failIcon from "../../assets/icons/failIcon.png";
import { withRouter } from "react-router";

import "./FailOrder.scss";

const FailOrder = () => {
  return (
    <div className="FailOrder">
      <FormModal>
        <div className="FailOrder__content">
          <Logo />
          <div className="FailOrder__fail">
            <img src={failIcon} alt="Fail Icon" />
            <p>
              Transaksioni nga ProCredit Bank është anuluar. Ju lutem provoni
              përsëri!
            </p>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default withRouter(FailOrder);
