import { useState, useEffect } from "react";

export function useHeightOfProducts(props) {
  const [height, setHeight] = useState();
  useEffect(() => {
    const heightOfProducts = document.getElementById("products-grid-column");
    if (heightOfProducts) {
      setTimeout(() => {
        setHeight(heightOfProducts.offsetHeight);
      }, 500);
    }
  });

  return height;
}
