const jwt_decode = require("jwt-decode");

export const loadMoreTexts = {
  default: "Më shumë",
  loading: "Duke u ngarkuar",
  allLoaded: "Nuk ka më shumë produkte"
};

export const generalTexts = {
  error: "Si duket diqka ka shkuar gabim, ju lutemi provoni përseri"
};

export const addProductTexts = {
  default: "Shto produktin",
  success: "Produkti u shtua",
  update: "Përditëso shportën"
};

export function getPathSlug(path) {
  const slug = path.replace(/\s/g, "").split("/");

  if (slug[slug.length - 1] === "") {
    return slug[slug.length - 2];
  }

  return slug[slug.length - 1];
}

export function getSubNavigationRoute(string) {
  const slug = string.split("/product-category/");
  return slug;
}

export function localUser() {
  const localUser = localStorage.getItem("BEGROSS_USER");

  if (!localUser) {
    return false;
  }

  return JSON.parse(localUser);
}

export function isTokenExpired(token) {
  const decoded = jwt_decode(token);

  return decoded.exp * 1000 < Date.now();
}

export function createLocalItems() {
  const localItems = {
    favorites: []
  };

  localStorage.setItem("MAXI_ITEMS", JSON.stringify(localItems));
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const m = ("0" + date.getMinutes()).substr(-2);
  const h = ("0" + date.getHours()).substr(-2);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return day + "/" + (monthIndex + 1) + "/" + year + " " + h + ":" + m;
}

export function getLocalItems() {
  let localItems = localStorage.getItem("MAXI_ITEMS");

  if (!localItems) {
    createLocalItems();

    localItems = localStorage.getItem("MAXI_ITEMS");
  }

  return localItems;
}

export function updateFavorites(productID) {
  const localItems = getLocalItems();

  const parsedItems = JSON.parse(localItems);
  let favorites = parsedItems.favorites;

  if (favorites.includes(productID)) {
    favorites = favorites.filter(function(e) {
      return e !== productID;
    });
  } else {
    favorites.push(productID);
  }

  localStorage.setItem(
    "MAXI_ITEMS",
    JSON.stringify({
      ...parsedItems,
      favorites
    })
  );
}

export function removeFavorite(productID) {
  const localItems = getLocalItems();

  const parsedItems = JSON.parse(localItems);

  if (!parsedItems) {
    return;
  }

  let favorites = parsedItems.favorites;

  if (favorites.includes(productID)) {
    favorites = favorites.filter(function(e) {
      return e !== productID;
    });
  }

  localStorage.setItem(
    "MAXI_ITEMS",
    JSON.stringify({
      ...parsedItems,
      favorites
    })
  );
}
