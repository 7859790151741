import React from "react";

import "./UserSettingsTitle.scss";

const UserSettingsTitle = props => {
  return (
    <div className="UserSettingsTitle">
      {props.title && (
        <h2 className="UserSettingsTitle__main">{props.title}</h2>
      )}
      {props.desc && <p className="UserSettingsTitle__desc">{props.desc}</p>}
    </div>
  );
};

export default UserSettingsTitle;
