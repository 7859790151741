import React from "react";
import "./RelatedProducts.scss";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { useQuery } from "@apollo/react-hooks";
import {GET_RELATED_PRODUCTS, RELATED_PRODUCTS} from "../../queries/relatedProductsQuery";

const RelatedProducts = (props) => {
  const relatedProductsIds = props.relatedProductsIds;
  const productCategoryId = props.productCategory;
  const selectedProductId = props.selectedProductId;
  const singleProduct = props.singleProduct;
  const singleRecipe = props.singleRecipe;


  const { data, loading, error } = useQuery(RELATED_PRODUCTS, {
    variables: {
      categoryId: productCategoryId,
      limit: props.limit,
    },
  });

  const { data: recipeProducts, loading: recipeProductsLoading, error: recipeProductsError } = useQuery(GET_RELATED_PRODUCTS, {
    variables: {
      productId: relatedProductsIds
    },
  });

  if (loading) {
    return <div> </div>;
  }

  if (error) {
    return <div> Error: {error.message}</div>;
  }

  if (recipeProductsLoading) {
    return <div> </div>;
  }

  if (recipeProductsError) {
    return <div> Error: {error.message}</div>;
  }

  const relatedProducts = data.products.nodes;
  const recipesProducts = recipeProducts.products.nodes;



  let filteredProducts = [];
  if (singleProduct) {
    const data = relatedProducts.filter((item, index) => {
      return !selectedProductId.includes(item.id);
    });
    filteredProducts = data;
  }

  return (
    <div className="RelatedProducts">
      <div className="products">
        {singleRecipe &&
        recipesProducts.map((product, index) => {
            return <ProductCard key={index} product={product} />;
          })}
        {singleProduct &&
          filteredProducts.map((product, index) => {
            return <ProductCard key={index} product={product} />;
          })}
      </div>
    </div>
  );
};

export default RelatedProducts;
