import React from "react";
import { FormGroup, Input } from "reactstrap";

const GeneralFormInput = props => {
  return (
    <FormGroup className="GeneralForm__input">
      <Input
        {...props}
        onChange={e => {
          props.onChange(e);
        }}
      />
    </FormGroup>
  );
};

export default GeneralFormInput;
