import { useWindowWidth } from "./useWindowWidth";

export const useResponseDimensions = () => {
  const width = useWindowWidth();

  return {
    isBigTablet: width <= 1000,
    isIphoneLandscape: width <= 900,
    isTablet: width <= 768,
    isMobile: width <= 512,
  };
};
