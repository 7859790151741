import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import CartIcon from "./../../assets/icons/cart-icon.svg";
import ArrowDown from "./../../assets/icons/arrow-down.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useCartContext } from "../../lib/CartContext/CartContext";

import { useHeightOfProducts } from "../../lib/hooks/useHeightOfProducts";
import { useResponseDimensions } from "../../lib/hooks/useResponseDimensions";
import { SideCartItem } from "./SideCartItem/SideCartItem";

import CartIconn from "../../assets/icons/cart-icon-desktop.svg";
import "./SideCart.scss";

export const SideCart = (props) => {
  const location = useLocation();
  const [mobileCartOpened, setMobileCart] = useState(false);
  const isTablet =
    useResponseDimensions().isBigTablet && location.pathname !== "/shporta";
  const heightOfProducts = useHeightOfProducts();
  const cartContext = useCartContext();
  const cartItems = cartContext.items;
  const itemCount = cartContext.cart?.itemCount;
  const cartTotal = cartContext.total?.replace("&nbsp;", "");

  useEffect(() => {
    cartContext.refetch();
  }, []);

  const loadMore = () => {
    cartContext.handleLoadMore();
  };

  if (cartContext.loading || !cartContext.items) {
    return (
      <div id="sidecart-container">
        <div
          className={`SideCart ${isTablet ? "mobile-cart" : ""} ${
            mobileCartOpened ? "cart-shown" : "cart-hidden"
          }`}
        >
          <div className="title d-flex justify-content-between">
            {isTablet && mobileCartOpened && (
              <div
                className="cart-close-mobile clickable"
                onClick={() => setMobileCart(false)}
              >
                <img src={ArrowDown} alt="" />
              </div>
            )}
          </div>
          <div className="body d-flex justify-content-center">
            {cartContext.isEmpty && (
              <p className="noProduct-inCart">
                Ju momentalisht nuk keni ndonje produkt ne shportë!
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <span>
      <div
        style={
          !isTablet && !props.singleProduct && !props.fromCheckout
            ? { height: heightOfProducts }
            : { height: "400px" }
        }
        id="side-cart"
        className={`SideCart ${isTablet ? "mobile-cart" : ""} ${
          mobileCartOpened ? "cart-shown" : "cart-hidden"
        }`}
      >
        <div className="box-container">
          <div className="sidecart-main">
            <div>
              <img src={CartIconn} alt="" />
            </div>
            <div className="sidecart-deatils">
              <p>{cartItems.length}</p>
              <p>Produkte në shportë</p>
            </div>

            {cartItems && cartItems.length !== 0 && (
              <div className="sidecart-details2">
                <p>Totali</p>
                <p>{cartTotal}</p>
              </div>
            )}
          </div>

          <div className="title">
            {isTablet && mobileCartOpened && (
              <div
                className="cart-close-mobile clickable"
                onClick={() => setMobileCart(false)}
              >
                <img src={ArrowDown} alt="" />
              </div>
            )}
          </div>

          <PerfectScrollbar ps-scroll-x>
            <div className="sideCart-scrollable-box">
              {cartItems && cartItems.length !== 0 && (
                <div className="main-menu">
                  <p> Produkti </p>
                  <p className="quantity-title"> Sasia </p>
                  <p className="price-title"> Çmimi</p>
                </div>
              )}
              <div className="body d-flex flex-column">
                {cartItems && cartItems.length === 0 && (
                  <p className="noProduct-inCart">
                    Ju momentalisht nuk keni ndonjë produkt në shportë!
                  </p>
                )}
                {cartItems.map((item) => {
                  return (
                    <SideCartItem
                      cartKey={item.key}
                      product={item.product}
                      quantity={item.quantity}
                      key={item.id}
                    />
                  );
                })}
              </div>
              {location.pathname !== "/shporta" &&
                location.pathname !== "/proceso-porosine" &&
                cartItems &&
                cartItems.length !== 0 && (
                  <div className="footer">
                    {cartContext.cart?.pageInfo.hasNextPage && (
                      <button
                        onClick={() => loadMore()}
                        className="btn btn-outline load-more-btn"
                      >
                        Më shumë
                      </button>
                    )}

                    <NavLink to="/shporta" className="checkout-button">
                      Porosit
                    </NavLink>
                  </div>
                )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      {isTablet && !mobileCartOpened && (
        <div
          id="cart-icon"
          className="cart-icon"
          onClick={() => setMobileCart(true)}
        >
          {cartItems.length > 0 && <span>{cartItems.length}</span>}
          <img src={CartIcon} alt="" />
        </div>
      )}
    </span>
  );
};
