import React from "react";
import { useDispatch } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useContext } from "react";
import "./UserSidebar.scss";
import { logoutUser } from "../../../actions/userActions";
import { emptyCart } from "../../../actions/cartActions";
import { GlobalContext } from "../../../contexts/GlobalContext";

const UserSidebar = (props) => {
  const globalContext = useContext(GlobalContext);
  const dispatch = useDispatch();

  const handleLogOut = (e) => {
    //TODO: commented for testing purpose
    // e.preventDefault();

    dispatch(emptyCart());
    dispatch(logoutUser());
    localStorage.removeItem("cart");
  };

  return (
    <div className="UserSidebar">
      <ul className="UserSidebar__list">
        <li>
          <NavLink to="/llogaria">Llogaria</NavLink>
        </li>
        <li>
          <NavLink to="/adresa">Adresa</NavLink>
        </li>
        <li>
          <NavLink to="/porosite">Porositë</NavLink>
        </li>
        <li>
          <a
            href="/"
            onClick={(e) => handleLogOut(e)}
            className="UserSidebar__logout"
          >
            Dil
          </a>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(UserSidebar);
