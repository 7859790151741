import React, { useState, useEffect, useContext, useRef } from "react";
import { Container } from "reactstrap";
import { HomeTopSection } from "./HomeTopSection/HomeTopSection";
import { SideCart } from "../../components/SideCart/SideCart";
import { ProductsGrid } from "./ProductsGrid/ProductsGrid";
import { useQuery } from "@apollo/react-hooks";
import Recipes from "../Recipes/Recipes";
import ProductsInFocus from "../../components/ProductsInFocus/ProductsInFocus";
import StockMessageModal from "../../components/StockMessageModal/StockMessageModal";
import RecommendedProducts from "../../components/RecommendedProducts/RecommendedProducts";
import { loadMoreTexts } from "../../assets/Helpers";
import { HOME_BANNERS } from "../../queries/homeBannersQuery";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useCartContext } from "../../lib/CartContext/CartContext";
import { useProductsContext } from "../../contexts/ProductsContext/ProductsContext";
import { useResponseDimensions } from "../../lib/hooks/useResponseDimensions";

import "./Home.scss";

const Home = (props) => {
  const cartContext = useCartContext();
  const globalContext = useContext(GlobalContext);
  const [showMoreButtonText] = useState(loadMoreTexts.default);
  const brandPath = props.match.params.pathParam1;
  const subCategoryPath = props.match.params.pathParam2;
  const [subCategory, setSubCategory] = useState(subCategoryPath);
  const myRef = useRef(null);
  const cartItems = cartContext.localCart;
  const ProductsCTX = useProductsContext();
  const isTablet = useResponseDimensions().isTablet;

  useEffect(() => {
    if (brandPath) {
      ProductsCTX.changeBrandPath(brandPath);
    }
    if (subCategoryPath) {
      ProductsCTX.changeSubCategoryPath(subCategoryPath);
    }
  });

  const {
    data: dataBanners,
    error: errorBanners,
    loading: loadingBanners,
  } = useQuery(HOME_BANNERS);

  if (loadingBanners) {
    return <div></div>;
  }

  if (errorBanners) {
    return <div> error </div>;
  }

  const banners = dataBanners.themeOptions.homePageBanners;
  const bannerImage1 = banners.banner1.mediaItemUrl;
  const bannerImage2 = banners.banner2.mediaItemUrl;
  const bannerLink1 = banners.banner1Link;
  const bannerLink2 = banners.banner2Link;

  const productsData = ProductsCTX.productsData;

  const products = productsData?.products;
  const handleOutsideClicked = () => {
    globalContext.handleOutsideCategoriesClick(true);
  };

  return (
    <div onMouseDown={() => handleOutsideClicked()} className="HomeSelection">
      <HomeTopSection />

      <Container>
        <div id="main" className="main row">
          <div className="col  col-12 col-sm-12 col-md-12 col-lg-9 ">
            <div ref={myRef} id="products-grid-column">
              <ProductsGrid productsData={productsData} />
            </div>
          </div>

          <div className="col col-12  col-sm-12   col-lg-3 ">
            <SideCart />
          </div>
        </div>

        {products?.pageInfo?.hasNextPage && (
          <div id="button-container">
            <button
              type="button"
              onClick={() => ProductsCTX.loadMore()}
              className="btn btn-outline"
              disabled={showMoreButtonText === loadMoreTexts.loading}
            >
              {showMoreButtonText}
            </button>
          </div>
        )}

        <div className="Banner-container">
          <ProductsInFocus
            bannerImage={bannerImage1}
            bannerLink={bannerLink1}
            title={"Produkte në fokus"}
          />
          <RecommendedProducts
            bannerImage={bannerImage2}
            bannerLink={bannerLink2}
            title={"Produkte të rekomanduara"}
          />
        </div>
        <StockMessageModal isOpen={globalContext.quantityModal} />
      </Container>

      <div className="Recipes_container">
        <Recipes home={true} limit={5} />
      </div>
    </div>
  );
};

export default Home;
