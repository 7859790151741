import React, { useEffect } from "react";
import FormModal from "../../components/FormModal/FormModal";
import { withRouter } from "react-router";
import Logo from "../../components/TopHeader/Logo/Logo";
import successIcon from "../../assets/images/tick-icon.png";
import "./SucessPage.scss";
import { useQuery } from "@apollo/react-hooks";
import { GET_ORDER_ID } from "../../queries/orderIdQuery";
import { useCartContext } from "../../lib/CartContext/CartContext";

const SuccessPage = (props) => {
  const orderId = props.match.params.slug;
  const cartCtx = useCartContext();

  const { data } = useQuery(GET_ORDER_ID, {
    variables: {
      orderId: orderId,
    },
  });

  useEffect(() => {
    // cartCtx.emptyCartt();
    localStorage.removeItem("woo-session");
    cartCtx.refetch();
  }, []);

  if (data?.order?.id) {
    setTimeout(() => {
      props.history.push(`/porosite/${data.order.id}`);
    }, 4000);
  } else {
    setTimeout(() => {
      props.history.push(`/`);
    }, 4000);
  }

  return (
    <div className="SuccessPage">
      <FormModal>
        <div className="SuccessPage__content">
          <Logo />
          <div className="SuccessPage__success">
            <img src={successIcon} alt="Succes Icon" />
            <p>Porosia juaj është procesuar me sukses. Faleminderit!</p>
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default withRouter(SuccessPage);
