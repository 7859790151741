import React, { useState, useEffect } from "react";
import CheckoutFormInput from "../../../components/GeneralForm/CheckoutFormInput";
import { useSelector } from "react-redux";
import list from "../../Register/listOfCities";
import { useResponseDimensions } from "../../../lib/hooks/useResponseDimensions";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./CheckoutData.scss";

const CheckoutData = (props) => {
  const isMobile = useResponseDimensions().isMobile;
  const userInfo = useSelector((state) => state.user);
  const { details, shippingDetails, account } = props;
  const [isCompany, setIsCompany] = useState();
  const [birthday, setBirthday] = useState("");

  useEffect(() => {
    if (details.company) {
      setIsCompany(true);
    }
  });

  const handleUpdate = (e, shipping = false) => {
    const { name, value } = e.target;
    if (!shipping) {
      props.onChange(name, value);
    } else {
      props.onShippingChange(name, value);
    }
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  return (
    <div className="CheckoutData">
      <div className="row mt-30">
        <div className="col-md-12 m-0">
          <div className="base-info-container">
            <div className="base-info-fields">
              <div className="row">
                <div className="col-md-8">
                  <p>Të dhënat bazë</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 col-12 col-sm-12  ">
                      <CheckoutFormInput
                        tabIndex="1"
                        label="Emri"
                        type="text"
                        name="firstName"
                        required
                        placeholder="Emri"
                        onChange={(e) => handleUpdate(e)}
                        value={details.firstName || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      <CheckoutFormInput
                        tabIndex="2"
                        label="Mbiemri"
                        type="text"
                        name="lastName"
                        required
                        placeholder="Mbiemri"
                        onChange={(e) => handleUpdate(e)}
                        value={details.lastName || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      <CheckoutFormInput
                        tabIndex="3"
                        label="Adresa"
                        type="text"
                        name="address"
                        required
                        placeholder="Adresa"
                        onChange={(e) => handleUpdate(e)}
                        value={details.address || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      <select
                        value={details.city}
                        required
                        tabIndex="4"
                        id="city"
                        name="city"
                        className="form-control select_city"
                        onChange={(e) => handleUpdate(e)}
                      >
                        <option id="city-placeholder" value="">
                          {" "}
                          Qyteti{" "}
                        </option>
                        {list.map((city, index) => (
                          <option id="city-option" key={index}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      <CheckoutFormInput
                        tabIndex="5"
                        type="text"
                        name="country"
                        placeholder="Shteti"
                        value={"Kosovë"}
                        onChange={(e) => handleUpdate(e)}
                        onBlur={(e) => handleOnBlur(e)}
                        disabled={"disabled"}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      <CheckoutFormInput
                        tabIndex="6"
                        label="Telefoni"
                        type="text"
                        name="telephone"
                        required
                        placeholder="Telefoni"
                        onChange={(e) => handleUpdate(e)}
                        value={details.telephone || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      {!userInfo.isLogged && (
                        <DatePicker
                          required
                          tabIndex="7"
                          label="Ditelindja"
                          placeholderText="Ditëlindja (dd/mm/yyyy) "
                          id="react-datepicker-checkout"
                          dateFormat="dd/MM/yyyy"
                          name="birthday"
                          selected={birthday}
                          onChange={(date) => {
                            let birthday = moment(date).format("DD/MM/YYYY");
                            setBirthday(date);
                            props.birthday(birthday);
                          }}
                        />
                      )}
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      {!userInfo.isLogged && (
                        <CheckoutFormInput
                          tabIndex="7"
                          label="E-mail"
                          type="text"
                          name="email"
                          required
                          placeholder="E-mail"
                          onChange={(e) => handleUpdate(e)}
                          value={details.email || ""}
                          onBlur={(e) => handleOnBlur(e)}
                        />
                      )}
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      {!userInfo.isLogged && (
                        <CheckoutFormInput
                          tabIndex="9"
                          label="Fjalëkalimi"
                          type="password"
                          name="password"
                          required
                          placeholder="Fjalëkalimi*"
                          onChange={(e) => handleUpdate(e)}
                          value={details.password || ""}
                          onBlur={(e) => handleOnBlur(e)}
                        />
                      )}
                    </div>

                    <div className="col-md-6 col-12 col-sm-12  ">
                      {!userInfo.isLogged && (
                        <CheckoutFormInput
                          tabIndex="10"
                          label="Konfirmo Fjalëkalimin"
                          type="password"
                          name="confirm_password"
                          required
                          placeholder="Konfirmo Fjalëkalimin"
                          onChange={(e) => handleUpdate(e)}
                          value={details.confirm_password || ""}
                          onBlur={(e) => handleOnBlur(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col col-md-4 ">
                  {!isMobile && !userInfo.isLogged && (
                    <div className="col-md-6 col-12 col-sm-12   is-company-checkbox">
                      <input
                        type="checkbox"
                        onChange={() => setIsCompany(!isCompany)}
                      />
                      <p> Jeni kompani ? </p>
                    </div>
                  )}
                  {isMobile && (
                    <div className="col-md-4 is-company-checkbox">
                      <input
                        type="checkbox"
                        onChange={() => setIsCompany(!isCompany)}
                      />
                      <p> Jeni kompani ? </p>
                    </div>
                  )}
                  {isCompany && (
                    <div className="company_container ">
                      <CheckoutFormInput
                        tabIndex="11"
                        label="Emri i kompanisë"
                        type="text"
                        name="company"
                        required
                        placeholder="Emri i kompanisë*"
                        onChange={(e) => handleUpdate(e)}
                        value={details.company || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                      <CheckoutFormInput
                        tabIndex="12"
                        label="Nr. Fiskal"
                        type="text"
                        name="fiscal_number"
                        required
                        placeholder="Nr. Fiskal"
                        onChange={(e) => handleUpdate(e)}
                        value={details.fiscal_number || ""}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutData;
