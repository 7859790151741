import React, { useState } from "react";

import ThemeSection from "../ThemeSection/ThemeSection";
import { loadMoreTexts, getLocalItems } from "../../assets/Helpers";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";

import ProductItem from "./ProductItem/ProductItem";
import ProductsLoader from "./ProductsContainer/ProductsLoader";
import ProductsContainer from "./ProductsContainer/ProductsContainer";
import ProductTitleLoader from "./ProductSectionTitle/ProductTitleLoader";
import ProductSectionTitle from "./ProductSectionTitle/ProductSectionTitle";

import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import "./ProductSection.scss";

const SECTION_QUERY = gql`
  query getProductSectionBySlug($slug: String, $limit: Int, $after: String) {
    productCategories(where: { slug: [$slug], search: $slug }) {
      nodes {
        id
        name
      }
    }
    products(where: { category: $slug }, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
        }
      }
    }
  }
`;

const ProductSection = (props) => {
  const { loading, error, data, fetchMore } = useQuery(
    props.customQuery || SECTION_QUERY,
    {
      variables: {
        after: "",
        slug: props.slug,
        limit: props.limit,
      },
    }
  );

  const localItems = getLocalItems(),
    localFavorites = JSON.parse(localItems).favorites;

  const [buttonText, setButtonText] = useState(loadMoreTexts.default);

  if (loading) {
    return (
      <>
        <ThemeSection>
          <ProductTitleLoader />
          <ProductsLoader total={props.limit} />
        </ThemeSection>
      </>
    );
  }

  if (error) {
    return (
      <>
        <ThemeSection>
          <p>Ju lutemi provoni përseri.</p>
        </ThemeSection>
      </>
    );
  }

  const isArchive = props.isArchive,
    products = data.products.nodes,
    category = data.productCategories.nodes[0];

  const handleClick = () => {
    setButtonText(loadMoreTexts.loading);

    fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (newNodes.length) {
          setButtonText(loadMoreTexts.default);
        }

        return newNodes.length
          ? {
              productCategories: data.productCategories,
              products: {
                __typename: previousResult.products.__typename,
                nodes: [...previousResult.products.nodes, ...newNodes],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  return (
    <>
      <ThemeSection>
        <ProductSectionTitle
          isArchive={isArchive}
          slug={props.slug}
          title={props.customTitle || category.name}
        />
        <ProductsContainer>
          {products.map((product) => {
            return (
              <ProductItem
                key={product.id}
                isFavorite={localFavorites.includes(product.productId)}
                isFavorites={props.isFavorites}
                {...product}
              />
            );
          })}
        </ProductsContainer>
      </ThemeSection>
      {isArchive && data.products.pageInfo.hasNextPage && (
        <LoadMoreButton title={buttonText} onClick={() => handleClick()} />
      )}
    </>
  );
};

export default ProductSection;
