const defaultValue = {
  id: null,
  isLogged: false,
  favorites: [],
};

const userReducer = (state = defaultValue, action) => {
  switch (action.type) {
    case "ADD_USER_INFO":
      return { ...action.payload };
    case "UPDATE_FAVORITES":
      return { ...state, favorites: [action.payload] };
    case "LOG_OUT_USER":
      return defaultValue;
    default:
      return state;
  }
};

export default userReducer;
