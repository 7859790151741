import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloLink, from } from "apollo-link";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";

import { typeDefs, resolvers } from "./schema";
import { localUser, isTokenExpired } from "./assets/Helpers";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_API,
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "CartItem":
        return object.key;
      default:
        return object.id || defaultDataIdFromObject(object);
    }
  },
});

const middleware = new ApolloLink((operation, forward) => {
  const session = localStorage.getItem("woo-session");
  const localUserInfo = localUser();
  const requestHeaders = {};

  if (
    localUserInfo &&
    (isTokenExpired(localUserInfo.AUTHTOKEN) ||
      isTokenExpired(localUserInfo.REFRESH_TOKEN))
  ) {
    localStorage.removeItem("BEGROSS_USER");
    localStorage.removeItem("woo-session");
  }

  if (localUserInfo && !isTokenExpired(localUserInfo.AUTHTOKEN)) {
    requestHeaders["authorization"] = `Bearer ${localUserInfo.AUTHTOKEN}`;
  }

  if (session && !isTokenExpired(session)) {
    requestHeaders["woocommerce-session"] = `Session ${session}`;
  }

  if (Object.entries(requestHeaders).length) {
    operation.setContext(() => ({
      headers: {
        ...requestHeaders,
      },
    }));
  }

  return forward(operation);
});

const afterware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    // Update session data.

    const context = operation.getContext();

    const {
      response: { headers },
    } = context;

    const session = headers.get("woocommerce-session");

    if (session && localStorage.getItem("woo-session") !== session) {
      localStorage.setItem("woo-session", session);
    }

    return response;
  })
);

const client = new ApolloClient({
  link: from([middleware, afterware, httpLink]),
  cache,
  clientState: {},
  typeDefs,
  resolvers,
});

export default client;
