import React from "react";
import ProductLoader from "../ProductItem/ProductLoader";
import ProductLoaderMobile from "../ProductItem/ProductLoaderMobile";
const ProductsLoader = props => {
  const items = [];
  
  for (let i = 0; i < props.total; i++) {
    items.push(i);
  }

  const _renderProductLoaders = array =>
    array.map(item => {
      return window.innerWidth > 991 ? <ProductLoader key={item} /> : <ProductLoaderMobile key={item} />;
    });

  return <div className="ProductsLoader">{_renderProductLoaders(items)}</div>;
};

export default ProductsLoader;
