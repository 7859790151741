import React, { useState } from "react";
import gql from "graphql-tag";
import { Container } from "reactstrap";
import { useQuery, useApolloClient } from "react-apollo";

import FormModal from "../../components/FormModal/FormModal";

import favoriteFull from "../../assets/images/favorite-full.svg";
import { loadMoreTexts, getLocalItems } from "../../assets/Helpers";
import ThemeSection from "../../components/ThemeSection/ThemeSection";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";

import NavigationButton from "../../components/Buttons/NavigationButton/NavigationButton";

import ProductItem from "../../components/ProductSection/ProductItem/ProductItem";
import ProductsLoader from "../../components/ProductSection/ProductsContainer/ProductsLoader";
import ProductsContainer from "../../components/ProductSection/ProductsContainer/ProductsContainer";
import ProductTitleLoader from "../../components/ProductSection/ProductSectionTitle/ProductTitleLoader";
import ProductSectionTitle from "../../components/ProductSection/ProductSectionTitle/ProductSectionTitle";

import "./Favorites.scss";

const FAVORITES_QUERY = gql`
  query getFavoriteItems($limit: Int, $items: [Int], $after: String) {
    products(where: { include: $items }, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
        }
      }
    }
  }
`;

const Favorites = () => {
  const localItems = getLocalItems(),
    localFavorites = JSON.parse(localItems).favorites;

  const [favorites, setFavorites] = useState(localFavorites || []);
  const [hasNoItems, setNoItems] = useState(favorites.length === 0);

  const [buttonText, setButtonText] = useState(loadMoreTexts.default);

  const { loading, error, data, fetchMore, refetch, client } = useQuery(
    FAVORITES_QUERY,
    {
      variables: {
        slug: "",
        after: "",
        limit: 90,
        items: favorites,
      },
    }
  );

  if (hasNoItems) {
    return (
      <div className="Favorites">
        <FormModal centered>
          <div className="Favorites__icon">
            <img src={favoriteFull} alt="Empty Favorites" />
          </div>
          <p className="Favorites__desc">
            Ju momentalisht nuk keni ndonje produkt ne listë!
          </p>
          <NavigationButton to="/" title="Shto produkte" />
        </FormModal>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="Favorites">
        <Container>
          <ThemeSection>
            <ProductTitleLoader />
            <ProductsLoader total="12" />
          </ThemeSection>
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="Favorites">
        <Container>
          <ThemeSection>
            <p>Ju lutemi provoni përseri.</p>
          </ThemeSection>
        </Container>
      </div>
    );
  }

  const isArchive = true,
    products = data.products.nodes;

  const handleClick = () => {
    setButtonText(loadMoreTexts.loading);

    fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (newNodes.length) {
          setButtonText(loadMoreTexts.default);
        }

        return newNodes.length
          ? {
              products: {
                __typename: previousResult.products.__typename,
                nodes: [...previousResult.products.nodes, ...newNodes],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  const handleRemove = (id) => {
    const cacheData = client.readQuery({
      query: FAVORITES_QUERY,
      variables: {
        slug: "",
        after: "",
        limit: 90,
        items: favorites,
      },
    });

    client.writeQuery({
      query: FAVORITES_QUERY,
      variables: {
        slug: "",
        after: "",
        limit: 90,
        items: favorites,
      },
      data: {
        products: {
          ...cacheData.products,
          nodes: cacheData.products.nodes.filter(
            (item) => item.productId !== id
          ),
        },
      },
    });

    const updatedData = client.readQuery({
      query: FAVORITES_QUERY,
      variables: {
        slug: "",
        after: "",
        limit: 90,
        items: favorites,
      },
    });

    setNoItems(updatedData && updatedData.products.nodes.length === 0);
  };

  return (
    <div className="Favorites">
      <Container>
        <ThemeSection>
          <ProductSectionTitle isArchive={isArchive} title="Lista ime" />
          <ProductsContainer>
            {products.map((product, index) => {
              return (
                <ProductItem
                  key={product.id}
                  isFavorite={true}
                  isFavorites={true}
                  onRemove={handleRemove}
                  {...product}
                />
              );
            })}
          </ProductsContainer>
        </ThemeSection>
        {isArchive && data.products.pageInfo.hasNextPage && (
          <LoadMoreButton title={buttonText} onClick={() => handleClick()} />
        )}
      </Container>
    </div>
  );
};

export default Favorites;
