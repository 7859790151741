import React, { useState, useEffect } from "react";
import { Form, Alert } from "reactstrap";
import { withRouter, NavLink } from "react-router-dom";
import GeneralFormInput from "../../components/GeneralForm/GeneralFormInput";
import { useDispatch } from "react-redux";
import { useMutation } from "react-apollo";

import "../../styles/general/_form.scss";
import "./Login.scss";
import { addUserInfo } from "../../actions/userActions";
import { LOGIN_MUTATION } from "../../queries/mutations/loginMutation";
import { useCartContext } from "../../lib/CartContext/CartContext";

const queryString = require("query-string");

const Login = (props) => {
  const dispatch = useDispatch();
  const params = queryString.parse(props.location.search);
  const [login, { loading, error, data }] = useMutation(LOGIN_MUTATION);
  const cartContext = useCartContext();
  const cartItems = cartContext.localCart;

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const isDisabled = !loginData.username || !loginData.password || loading,
    invalidLogin = error && error.message;

  // useEffect(() => {
  //   if (error && error.message) {
  //     recaptchaRef.current.props.grecaptcha.reset();
  //   }
  // }, [error]); //

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const recaptchaValue = recaptchaRef.current.getValue();
    //
    // if (!recaptchaValue) {
    //   toggleErrors(true);
    //   return;
    // } else {
    //   toggleErrors(false);
    // }

    try {
      const { data } = await login({
        variables: {
          ...loginData,
          captcha: "recaptchaValue",
        },
      });

      const userData = data.login;

      const userInfo = {
        ID: userData.user.id,
        NAME: userData.user.firstName,
        AUTHTOKEN: userData.authToken,
        REFRESH_TOKEN: userData.refreshToken,
        userId: userData.user.userId,
        firstName: userData.user.firstName,
        lastName: userData.user.lastName,
      };

      localStorage.setItem("BEGROSS_USER", JSON.stringify(userInfo));

      dispatch(
        addUserInfo({
          id: userInfo.ID,
          isLogged: true,
          firstName: data.login.user.firstName,
          lastName: data.login.user.lastName,
          userId: data.login.user.userId,
        })
      );

      if (params.toCart === "true") {
        props.history.push("/");

        setTimeout(() => {
          props.history.push("/shporta");
        }, 1000);
      } else {
        props.history.replace("/");
      }
    } catch (error) {
      console.error("Couldn't login, please try again!");
      toggleErrors(true);
    }
  };

  return (
    <div className="container Login">
      <div className="row mt-70">
        <div className="col d-flex justify-content-center">
          <h1>Kyçu</h1>
        </div>
      </div>
      <div className="row mt-30 mb-100 d-flex justify-content-center">
        <div style={{ width: "304px" }}>
          <Form onSubmit={(e) => handleSubmit(e)}>
            {invalidLogin && (
              <Alert color="warning">
                Email apo fjalëkalimi nuk është i vlefshëm!
              </Alert>
            )}

            {/*{hasErrors && !captchaToken && (*/}
            {/*  <Alert color="warning">*/}
            {/*    Ju lutemi plotësoni reCaptcha e mëposhtme!*/}
            {/*  </Alert>*/}
            {/*)}*/}

            <GeneralFormInput
              type="text"
              name="username"
              placeholder="Email"
              value={loginData.username || ""}
              onChange={(e) => handleChange(e)}
            />
            <GeneralFormInput
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange(e)}
              value={loginData.password || ""}
            />

            <p className="GeneralForm__text text-right">
              <NavLink to="/perditeso-fjalekalimin">
                Keni harruar fjalëkalimin?
              </NavLink>
            </p>
            {/*<div className="GeneralForm__input GeneralForm__input--recaptcha">*/}
            {/*  <ReCAPTCHA*/}
            {/*    ref={recaptchaRef}*/}
            {/*    sitekey="6Lc2dM4UAAAAADXFx7_Ey4CnEdt3I5P1-2xZen01"*/}
            {/*    onChange={(e) => {*/}
            {/*      setCaptchaToken(e);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <div
              className={
                "mt-30 d-flex justify-content-center GeneralForm__input" +
                (isDisabled ? " GeneralForm__input--disabled" : "")
              }
            >
              <input
                value={loading ? " Duke u Kyçur" : "Kyçu"}
                type="submit"
                disabled={isDisabled}
              />
            </div>
            <p
              className="GeneralForm__text text-center"
              style={{ marginTop: "10px" }}
            >
              <NavLink to="/regjistrohu">
                Nuk keni llogari? Regjistrohuni këtu
              </NavLink>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
