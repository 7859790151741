import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ProductCard } from "../ProductCard/ProductCard";
import { Link } from "react-router-dom";
import FlickitySlider from "../FlickitySlider/FlickitySlider";
import { PRODUCTS_IN_FOCUS } from "../../queries/productsInFocusQuery";

import "./ProductsInFocus.scss";

const ProductsInFocus = (props) => {
  const [sliderStatus, setSliderStatus] = useState("started");
  const bannerImage = props.bannerImage;
  const bannerLink = props.bannerLink;

  const { data, loading, error } = useQuery(PRODUCTS_IN_FOCUS);

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div> {error.message}</div>;
  }

  const products = data.products.nodes;

  return (
    <div className="ProductsInFocus">
      <p className="ProductsInFocus__title">{props.title}</p>
      <div className="ProductsInFocus__content">
        <Link to={bannerLink} className="banner">
          <img className="banner-image" src={bannerImage} alt="" />
        </Link>
        <div className="slider-wrapper">
          <FlickitySlider
            data-flickity
            productInFocus={true}
            options={{
              freeScroll: true,
              contain: true,
              wrapAround: false,
              groupCells: true,
              prevNextButtons: true,
              pageDots: false,
            }}
          >
            {products.map((product) => {
              return (
                <ProductCard
                  productFocus={true}
                  key={product.id}
                  product={product}
                />
              );
            })}
          </FlickitySlider>
        </div>
      </div>
    </div>
  );
};

export default ProductsInFocus;
