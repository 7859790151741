import React, {useState, useEffect} from "react";
import { useQuery } from "react-apollo";
import { Container } from "reactstrap";
import SingleProduct from "./SingleProduct/SingleProduct";
import RelatedProducts from "../RelatedProducts/RelatedProducts";
import { SideCart } from "../../components/SideCart/SideCart";
import { GET_SINGLE_PRODUCT } from "../../queries/singleProductQuery";

import "./SingleProductContainer.scss";

const SingleProductContainer = (props) => {
  const slug = props.match.params.slug;
  const [productIsMeat, setProductIsMeat] = useState(false);




  const { data, loading, error } = useQuery(GET_SINGLE_PRODUCT, {
    variables: {
      slug: slug,
    },
  });

  if (loading) {
    return <div> </div>;
  }

  if (error) {
    return <div> Error: {error.message}</div>;
  }

  const product = data.products.nodes[0];
  const productCategories = product.productCategories.nodes;




  const lastProductCategory =
    productCategories[productCategories.length - 1].productCategoryId;

  const productCategoryIds = productCategories.map((cat) =>
    cat.productCategoryId.toString()
  );

  return (
    <div className="SingleProductContainer">
      <Container>
        <div className="row mt-40">
          <div className="col  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 ">
            {!loading && <SingleProduct {...product} productCategories={productCategories} />}
            <div className="line mt-20 mb-20"></div>
            {!loading && (
              <div className="row">
                <p className="title m-0">Produkte të ngjashme</p>
                <RelatedProducts
                  singleProduct={true}
                  limit={13}
                  productCategory={lastProductCategory}
                  selectedProductId={product.id}
                />
              </div>
            )}
          </div>

          <div className="SingleProductContainer__side-cart col col-12 col-md-3 col-lg-3 col-xl-3">
            <SideCart singleProduct={true} />
          </div>
        </div>
        {/*<div className="row">*/}
        {/*  <Recipes*/}
        {/*    showTitle={true}*/}
        {/*    categoryIds={productCategoryIds}*/}
        {/*    singleProduct={true}*/}
        {/*    limit={5}*/}
        {/*  />*/}
        {/*</div>*/}
      </Container>
    </div>
  );
};

export default SingleProductContainer;
