import React, { useState } from "react";

import v4 from "uuid/v4";
import gql from "graphql-tag";
import { Alert } from "reactstrap";
import { useMutation } from "react-apollo";
import ReCAPTCHA from "react-google-recaptcha";

import "./ResetPasswordButton.scss";

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`;

const ResetPasswordButton = (props) => {
  const [
    sendPasswordResetEmail,
    { data: resetData, loading: loadingReset, error: resetError },
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const recaptchaRef = React.createRef();

  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  if (!props.userEmail) {
    return (
      <div className="ResetPasswordButton">
        <div className={"GeneralForm__input"}>Duke u ngarkuar...</div>
      </div>
    );
  }

  if (loadingReset) {
    return <p className="FormModal__error">Ju lutem prisni...</p>;
  }

  if (resetData) {
    return (
      <p className="FormModal__error">
        Linku për përditësim të fjalëkalimit ju është dërguar në email!
      </p>
    );
  }

  if (resetError) {
    return <p className="FormModal__error">Ju lutemi provoni përseri!</p>;
  }

  const handlePasswordReset = (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      toggleErrors(true);
      return;
    } else {
      toggleErrors(false);
    }

    sendPasswordResetEmail({
      variables: {
        input: {
          clientMutationId: v4(),
          username: props.userEmail,
          captcha: recaptchaValue,
        },
      },
    });
  };

  return (
    <div className="ResetPasswordButton">
      {hasErrors && !captchaToken && (
        <Alert color="warning">
          Ju lutemi plotësoni reCaptcha e mëposhtme!
        </Alert>
      )}
      <div className={"GeneralForm__input"}>
        <div className="GeneralForm__input GeneralForm__input--recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lc2dM4UAAAAADXFx7_Ey4CnEdt3I5P1-2xZen01"
            onChange={(e) => {
              setCaptchaToken(e);
            }}
          />
        </div>
        <input
          value="Dërgo përditësimin e passwordit në email"
          type="submit"
          onClick={(e) => {
            handlePasswordReset(e);
          }}
        />
      </div>
    </div>
  );
};

export default ResetPasswordButton;
