import React from "react";
import { Row, Col } from "reactstrap";
import CheckoutFormInput from "../../GeneralForm/CheckoutFormInput";
import { NavLink, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_CITIES } from "../../../useCartQueries";

import "./BillingDetails.scss";
import list from "../../../containers/Register/listOfCities";

const BillingDetails = (props) => {
  const { details, account } = props;

  // const citiesQuery = useQuery(GET_CITIES);
  // React.useEffect(() => {
  //   citiesQuery.refetch();
  // });
  let location = useLocation();
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    props.onChange(name, value);
  };

  const handleAccountUpdate = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    props.onChangeAccount(name, value);
  };
  const handleCityChange = (city) => {
    if (location.pathname !== "/adresa") {
      return;
    }
    props.onChange("city", city);
  };
  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  return (
    <div className="BillingDetails">
      <div className="CheckoutTitle">
        <h5>Detajet për faturë</h5>
      </div>
      <Row>
        <Col xs="12" sm="6">
          <CheckoutFormInput
            label="Emri"
            placeholder="Emri"
            required
            id="firstName"
            name="firstName"
            type="text"
            onChange={(e) => handleUpdate(e)}
            value={details.firstName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        <Col xs="12" sm="6">
          <CheckoutFormInput
            label="Mbiemri"
            placeholder="Mbiemri"
            required
            type="text"
            id="lastName"
            name="lastName"
            onChange={(e) => handleUpdate(e)}
            value={details.lastName || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        {details.company && (
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label="Kompania"
              placeholder="Kompania"
              type="text"
              id="company"
              name="company"
              onChange={(e) => handleUpdate(e)}
              value={details.company || ""}
            />
          </Col>
        )}

        <Col xs="12" sm="12">
          <select
            value={details.city}
            required
            tabIndex="4"
            id="city"
            name="city"
            className="form-control select_city"
            onChange={(e) => handleUpdate(e)}
          >
            <option id="city-placeholder" value="">
              {" "}
              Qyteti{" "}
            </option>
            {list.map((city, index) => (
              <option id="city-option" key={index}>
                {city.name}
              </option>
            ))}
          </select>
        </Col>

        <Col xs="12" sm="12">
          <CheckoutFormInput
            label="Adresa"
            placeholder="Adresa"
            required
            type="text"
            id="address"
            name="address"
            onChange={(e) => handleUpdate(e)}
            value={details.address || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        <Col xs="12" sm="12">
          <CheckoutFormInput
            label="Telefoni"
            placeholder="Telefoni"
            required
            type="text"
            id="telephone"
            name="telephone"
            onChange={(e) => handleUpdate(e)}
            value={details.telephone || ""}
            onBlur={(e) => handleOnBlur(e)}
          />
        </Col>
        {details.guestCheckout && (
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label="Email"
              placeholder="Email"
              required
              type="email"
              id="email"
              name="email"
              onChange={(e) => handleAccountUpdate(e)}
              value={account.email || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        )}
        {details.guestCheckout && (
          <Col xs="6" sm="6">
            <CheckoutFormInput
              label="Fjalëkalimi"
              placeholder="Fjalëkalimi"
              required
              type="password"
              id="password"
              name="password"
              onChange={(e) => handleAccountUpdate(e)}
              value={account.password || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        )}
        {details.guestCheckout && (
          <Col xs="6" sm="6">
            <CheckoutFormInput
              label="Konfirmo Fjalëkalimin"
              placeholder="Konfirmo Fjalëkalimin"
              required
              type="password"
              id="passwordConfrim"
              name="passwordConfrim"
              onChange={(e) => handleAccountUpdate(e)}
              value={account.passwordConfrim || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default BillingDetails;
