import gql from "graphql-tag";

export const USER_BILLING_DATA = gql`
  query billingQuery($id: ID) {
    customer(id: $id) {
      id
      firstName
      billing {
        firstName
        lastName
        address1
        address2
        city
        phone
        email
        state
        country
        company
        postcode
      }
    }
  }
`;

export const USER_SHIPPING_DATA = gql`
  query shippingQuery($id: ID) {
    customer(id: $id) {
      id
      shipping {
        firstName
        lastName
        address1
        address2
        city
        phone
        email
        country
        state
        company
        postcode
      }
    }
  }
`;
