import gql from "graphql-tag";

export const GET_HOME_SLIDER = gql`
  query getHomeSlider {
    themeOptions {
      homePageSlider {
        logo {
          id
          mediaItemUrl
        }
        sliderItems {
          image {
            mediaItemUrl
          }
          mobileImage {
            mediaItemUrl
          }
          link {
            url
            openInNewTab
          }
        }
      }
    }
  }
`;
export const GET_HOME_INFO = gql`
  query getHomeInfo {
    themeOptions {
      homePageInfo {
        info {
          link
          photo {
            mediaItemUrl
          }
          mobilePhoto {
            mediaItemUrl
          }
          subtitle
          title
        }
      }
    }
  }
`;
