import React, { useEffect, useCallback } from "react";
import closeIcon from "../../assets/icons/closeIcon4.svg";

import "./PreviewImageModal.scss";

const PreviewImageModal = (props) => {
  const imgSrc = props.imgSrc;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      props.closeModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (props.isOpen) {
    return (
      <div className={props.isOpen ? "modal-image active" : "modal-image"}>
        <img
          src={closeIcon}
          className="close-modal-button"
          onClick={props.toggle}
        />
        <img className="modal-content" src={imgSrc} alt="" />
        <div id="caption"></div>
      </div>
    );
  } else {
    return null;
  }
};

export default PreviewImageModal;
