import gql from "graphql-tag";

export const CHECKOUT_MUTATION = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      clientMutationId
      order {
        id
        paymentMethod
        orderId
      }
      customer {
        jwtAuthToken
        jwtRefreshToken
        id
        firstName
        lastName
        customerId
      }
      redirect
      result
    }
  }
`;
