import React from "react";
import { Container } from "reactstrap";
import ProductSection from "../../components/ProductSection/ProductSection";
import { getPathSlug } from "../../assets/Helpers";

import "./Archive.scss";

const Archive = (props) => {
  const productSlug = props.location.pathname;
  return (
    <div className="Archive">
      <Container>
        {/* <Advertiser sectionSource="https://www.kallxo.com/ads/www/images/e0b5e5df0abcde2b6dff083f8465e173.gif" /> */}
        <ProductSection limit="24" isArchive slug={getPathSlug(productSlug)} />
      </Container>
    </div>
  );
};

export default Archive;
