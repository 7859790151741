import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  GET_CART,
  ADD_TO_CART,
  UPDATE_ITEM_QUANTITIES,
  CHECK_CART,
} from "./useCartQueries";
import v4 from "uuid/v4";

function useCartMutations(productId, variationId) {
  const { data: cartData } = useQuery(CHECK_CART, {
    variables: { productId, variationId },
  });

  const [addToCart] = useMutation(ADD_TO_CART, {
    update(
      cache,
      {
        data: {
          addToCart: { cartItem },
        },
      }
    ) {
      const { cart } = cache.readQuery({ query: GET_CART });
      const { contents } = cart;

      const exists = contents.nodes.filter((node) => node.key === cartItem.key);

      if (exists.length === 0) {
        contents.nodes.push(cartItem);
      } else {
        const index = contents.nodes.indexOf(exists[0]);

        contents.nodes[index].quantity = cartItem.quantity;
      }

      cache.writeQuery({
        query: GET_CART,
        data: { cart: { ...cart, contents } },
      });
    },
    refetchQueries: ({ data }) => {
      const { product, variation } = data.addToCart.cartItem;

      return [
        {
          query: CHECK_CART,
          variables: {
            productId: product.productId,
            variationId: variation ? variation.variationId : null,
          },
        },
      ];
    },
  });

  const [updateItemQuantities] = useMutation(UPDATE_ITEM_QUANTITIES, {
    update(
      cache,
      {
        data: {
          updateItemQuantities: { items, removed },
        },
      }
    ) {
      const { cart } = cache.readQuery({ query: GET_CART });

      const nodes =
        removed.length > 0
          ? cart.contents.nodes.filter((node) => node.key !== removed[0].key)
          : cart.contents.nodes;

      const contents = { ...cart.contents, nodes };

      cache.writeQuery({
        query: GET_CART,
        data: { cart: { ...cart, contents } },
      });
    },

    refetchQueries: ({ data }) => {
      const { updated, removed } = data.updateItemQuantities;
      const mapper = ({ product, variation }) => ({
        query: CHECK_CART,
        variables: {
          productId: product.productId,
          variationId: variation ? variation.variationId : null,
        },
      });

      return [...updated.map(mapper), ...removed.map(mapper)];
    },
  });

  return {
    itemInCart: cartData && cartData.isInCart ? cartData.getCartItem : false,
    addToCart: (id, quantity, vId, variation, options = {}) => {
      addToCart({
        variables: {
          input: {
            clientMutationId: v4(),
            productId: id,
            quantity,
            variationId: vId,
            variation,
          },
        },
        ...options,
      });
    },
    updateItemQuantities:
      cartData && cartData.getCartItem
        ? (quantity, options = {}) => {
            return updateItemQuantities({
              variables: {
                input: {
                  clientMutationId: v4(),
                  items: [{ key: cartData.getCartItem.key, quantity }],
                },
              },
              ...options,
            });
          }
        : (items, options) => {
            return updateItemQuantities({
              variables: {
                input: {
                  clientMutationId: v4(),
                  items,
                },
              },
              ...options,
            });
          },
  };
}

export default useCartMutations;
