import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "react-apollo";

import v4 from "uuid/v4";
import { string } from "yup";
import gql from "graphql-tag";
import { Form, Alert } from "reactstrap";

import FormModal from "../../../components/FormModal/FormModal";
import FormTitle from "../../../components/FormModal/FormTitle/FormTitle";
import GeneralFormInput from "../../../components/GeneralForm/GeneralFormInput";

import "../ForgotPassword.scss";

// DONE: (MUST)  recaptcha-token key duhet te jete i njejt me register , reset, dhe login .

const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($inputData: ResetUserPasswordInput!) {
    resetUserPassword(input: $inputData) {
      clientMutationId
      user {
        firstName
      }
    }
  }
`;

const ResetPasswordAccount = (props) => {
  const [formData, setFormData] = useState({
    password: "",
  });

  const [validPassword, setValidPassword] = useState(true);

  const [
    resetUserPassword,
    { data: resetData, loading: loadingReset, error: resetError },
  ] = useMutation(RESET_USER_PASSWORD);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const schema = string().matches(/^(?=.*\d)(?=.*[a-zA-Z]).{7,}$/);

  if (loadingReset) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">Ju lutem prisni...</p>
      </FormModal>
    );
  }

  if (resetData) {
    setTimeout(() => {
      props.history.push("/");
    }, 5000);

    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">Fjalëkalimi juaj është përditësuar!</p>
      </FormModal>
    );
  }

  if (resetError) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          {resetError.message.replace("GraphQL error: ", "")}
        </p>
      </FormModal>
    );
  }

  const isDisabled = formData.password === "" || loadingReset;

  return (
    <FormModal halfModal={true}>
      <FormTitle title="Fjalëkalimi i ri" />
      <div className="GeneralForm">
        <Form
          onSubmit={async (e) => {
            e.preventDefault();

            try {
              const isvalid = await schema.isValid(formData.password);

              if (!isvalid) {
                setValidPassword(false);
                return;
              }

              resetUserPassword({
                variables: {
                  inputData: {
                    clientMutationId: v4(),
                    key: props.resetKey,
                    login: props.login,
                    password: formData.password,
                  },
                },
              });
            } catch (error) {}
          }}
        >
          {!validPassword && (
            <Alert color="warning">
              Fjalëkalimi duhet të përmbajë së paku 8 karaktere dhe një numër!
            </Alert>
          )}
          <GeneralFormInput
            name="password"
            type="password"
            placeholder="Shënoni fjalëkalimin e ri"
            onChange={(e) => handleChange(e)}
            value={formData.password || ""}
          />
          <div
            className={
              "GeneralForm__input" +
              (isDisabled ? " GeneralForm__input--disabled" : "")
            }
          >
            <input
              type="submit"
              disabled={isDisabled}
              value={loadingReset ? "Duke u përditësuar" : "Përditëso"}
            />
          </div>
        </Form>
      </div>
    </FormModal>
  );
};

export default withRouter(ResetPasswordAccount);
