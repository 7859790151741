const  list = [
    {id: 1, name: 'Prishtinë'},
    {id: 2, name: 'Prizren'},
    {id: 3, name: 'Ferizaj'},
    {id: 4, name: 'Pejë'},
    {id: 5, name: 'Gjakovë'},
    {id: 6, name: 'Gjilan'},
    {id: 7, name: 'Fushë Kosovë'},
    {id: 8, name: 'Mitrovicë'},
    {id: 9, name: 'Podujevë'},
    {id: 10, name: 'Vushtrri'},
    {id: 11, name: 'Suarekë'},
    {id: 12, name: 'Lipjan'},
    {id: 13, name: 'Malishevë'},
    {id: 14, name: 'Kamenicë'},
    {id: 15, name: 'Viti'},
    {id: 16, name: 'Decan'},
    {id: 17, name: 'Istog'},
    {id: 18, name: 'Klinë'},
    {id: 19, name: 'Skenderaj'},
    {id: 20, name: 'Kaqanik'},
    {id: 23, name: 'Drenas'},
];


export default list;
