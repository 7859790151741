import "react-app-polyfill/ie9";
import React from "react";
import App from "./App";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.min.css";

// Redux
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducers from "./reducers/reducers";

import client from "./apollo-client";
import { ApolloProvider } from "react-apollo";

// Redux
export const store = createStore(
  rootReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>,
  document.getElementById("root")
);
