import React from "react";
import { Link } from "react-router-dom";
import { Input, Label } from "reactstrap";
import { useCartContext } from "../../../lib/CartContext/CartContext";

import "./CheckoutTotalSubmit.scss";

//TODO: this component should be refactor --->  old context used should be replaced with new cart context

const CheckoutTotalSubmit = (props) => {
  const cartContext = useCartContext();
  const cart = cartContext.cartData?.cart;
  const totalPrice = cartContext.cartData?.cart?.total;
  const subtotal = cartContext.cartData?.cart?.subtotal;
  const discountTotal = cartContext.cartData?.cart?.discountTotal;
  const discountTotalInt = parseFloat(discountTotal?.replace("&nbsp;€", ""));

  console.log(discountTotalInt);

  return (
    <div className="CheckoutTotalSubmit">
      <div className="d-flex flex-column justify-content-end align-items-end">
        <p className="mb-0">Totali në shportë:</p>

        {discountTotalInt > 0.0 ? (
          <>
            <h3 className="CheckoutTotalSubmit__subtotal-price">
              {subtotal?.replace("&nbsp;", "")}
            </h3>
            <h3 className="price mb-10">{totalPrice?.replace("&nbsp;", "")}</h3>
          </>
        ) : (
          <h3 className="price mb-10">{totalPrice?.replace("&nbsp;", "")}</h3>
        )}

        <div className="terms-conditions">
          <Label check>
            <Input type="checkbox" id="terms-conditions" required />
            <p className="m-0">
              Unë pranoj{" "}
              <Link to="/informacione/kushtet-e-perdorimit">
                Termet dhe Kushtet
              </Link>{" "}
              e kompanisë.
            </p>
          </Label>
        </div>
        {cartContext.total &&
          parseFloat(cartContext.total.replace("&nbsp;€", "")) < 30 && (
            <div className="terms-conditions">
              <p className="alert-warning m-0">
                Shuma minimale për porosi është 30€*
              </p>
            </div>
          )}

        {cartContext.total &&
          parseFloat(cartContext.total.replace("&nbsp;€", "")) >= 30 && (
            <div className="d-flex align-items-center mt-10">
              {props.loading && <div className="small-loader" />}
              <button
                type="submit"
                className={
                  props.loading ||
                  (cartContext.items &&
                    parseFloat(cartContext.total.replace("&nbsp;€", "")) < 30)
                    ? "checkout-button ml-15 not_active"
                    : "checkout-button ml-15"
                }
              >
                Proceso
              </button>
            </div>
          )}
      </div>
    </div>
  );
};
export default CheckoutTotalSubmit;
