import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import CheckoutFormInput from "../../GeneralForm/CheckoutFormInput";
import { useLocation } from "react-router-dom";
import "./ShippingDetails.scss";
import { useQuery } from "@apollo/react-hooks";
import { GET_CITIES } from "../../../useCartQueries";
import list from "../../../containers/Register/listOfCities";

const ShippingDetails = (props) => {
  const { details, comment } = props;

  // const citiesQuery = useQuery(GET_CITIES);
  // React.useEffect(() => {
  //   citiesQuery.refetch();
  // });
  let location = useLocation();
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    props.onChange(name, value);
  };

  const handleUpdateComment = (e) => {
    const { name, value } = e.target;

    e.target.className = "form-control";
    props.onChangeComment(name, value);
  };

  const handleCityChange = (city) => {
    if (location.pathname !== "/adresa") {
      return;
    }
    props.onChange("city", city);
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  return (
    <div className="ShippingDetails">
      {props.shippingTitle ? (
        <div className="CheckoutTitle">
          <h5>Detajet për adresë</h5>
        </div>
      ) : (
        <div className="CheckoutTitle mb6">
          <CheckoutFormInput
            label="Dërgo në një adresë tjetër"
            type="checkbox"
            id="differentShipping"
            name="differentShipping"
            value={details.differentShipping || false}
            onChange={(e) => props.toggleShipping()}
          />
        </div>
      )}
      {details.differentShipping && (
        <Row>
          <Col xs="12" sm="6">
            <CheckoutFormInput
              label="Emri"
              placeholder="Emri"
              required
              id="firstName"
              name="firstName"
              type="text"
              onChange={(e) => handleUpdate(e)}
              value={details.firstName || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
          <Col xs="12" sm="6">
            <CheckoutFormInput
              label="Mbiemri"
              placeholder="Mbiemri"
              required
              type="text"
              id="lastName"
              name="lastName"
              onChange={(e) => handleUpdate(e)}
              value={details.lastName || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
          {details.company && (
            <Col xs="12" sm="12">
              <CheckoutFormInput
                label="Kompania"
                placeholder="Kompania"
                type="text"
                id="company"
                name="company"
                onChange={(e) => handleUpdate(e)}
                value={details.company || ""}
              />
            </Col>
          )}

          <Col xs="12" sm="12">
            <select
              value={details.city}
              required
              tabIndex="4"
              id="city"
              name="city"
              className="form-control select_city"
              onChange={(e) => handleUpdate(e)}
            >
              <option id="city-placeholder" value="">
                {" "}
                Qyteti{" "}
              </option>
              {list.map((city, index) => (
                <option id="city-option" key={index}>
                  {city.name}
                </option>
              ))}
            </select>
          </Col>
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label="Adresa"
              placeholder="Adresa"
              required
              type="text"
              id="address"
              name="address"
              onChange={(e) => handleUpdate(e)}
              value={details.address || ""}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Col>
        </Row>
      )}
      {!props.shippingTitle && (
        <Row>
          <Col xs="12" sm="12">
            <CheckoutFormInput
              label="Komenti"
              placeholder="Komenti"
              type="textarea"
              id="comment"
              name="comment"
              value={comment || ""}
              onChange={(e) => handleUpdateComment(e)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ShippingDetails;
