import React, { useState } from "react";
import { Container } from "reactstrap";
import { NavLink } from "react-router-dom";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  const cookies = JSON.parse(localStorage.getItem("cookies"));
  const [showCookies, setShowCookies] = useState(
    cookies === false ? cookies : true
  );

  const handleCookies = () => {
    setShowCookies(false);
    localStorage.setItem("cookies", JSON.stringify(false));
  };

  return (
    <div
      className={
        showCookies ? "PrivacyPolicy PrivacyPolicy--visible" : "PrivacyPolicy"
      }
    >
      <Container>
        <div className="PrivacyPolicy__container">
          <p className="PrivacyPolicy__desc">
            Duke përdorur shërbimet e <b>Begmart</b> ju pajtoheni me përdorimin
            tonë të cookies.
          </p>
          <ul className="PrivacyPolicy__links">
            <li>
              <NavLink to="/informacione/kushtet-e-perdorimit">
                Lexo më shumë
              </NavLink>
            </li>
            <li>
              <button onClick={(e) => handleCookies(e)}>Kuptoj</button>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
