import React from "react";
import { FormGroup, Input } from "reactstrap";

import "./CheckoutFormInput.scss";

const CheckoutFormInput = (props) => {
  return (
    <FormGroup className={"GeneralForm__input " + props.classNames}>
      <Input
        {...props}
        onChange={(e) => {
          props.onChange(e);
        }}
      />
    </FormGroup>
  );
};

export default CheckoutFormInput;
