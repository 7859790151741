import React, { useState, useEffect } from "react";
import plusIcon from "../../../assets/icons/plusIcon.svg";
import minusIcon from "../../../assets/icons/minusIcon.svg";
import PreviewImageModal from "../../../components/PreviewImageModal/PreviewImageModal";
import useModal from "../../../components/PreviewImageModal/showModal/useModal";
import useStockModal from "../../../components/StockMessageModal/showModal/useStockModal";
import { useCartContext } from "../../../lib/CartContext/CartContext";
import StockMessageModal from "../../../components/StockMessageModal/StockMessageModal";

import "./SingleProduct.scss";

const SingleProduct = (props) => {
  const [productIsMeat, setProductIsMeat] = useState();
  const { productId, id, stockStatus, productCategories } = props;
  const cartContext = useCartContext();
  const { showModal, toggle, closeModal } = useModal();
  const {
    showQuantityModal,
    toggleQuantityModal,
    closeQuantityModal,
  } = useStockModal();
  const [itemQuantity, setItemQuantity] = useState(1),
    [newOrderButton, setNewOrderButton] = useState("+ SHPORTË");
  const [updateButton, setUpdateButton] = useState("PËRDITËSO ");
  const maxQuantity = props.stockQuantity;
  const [imgSrc, setImgSrc] = useState();

  useEffect(() => {
      productCategories.map(item => {
        if(item.productCategoryId === 90) {
          setProductIsMeat(true)
        }
      })
  }, [])

  const handleOpenImage = (image) => {
    setImgSrc(image);
    toggle();
  };

  const handleAddToCart = (add, update) => {
    if (add) {
      if (itemQuantity <= maxQuantity) {
        cartContext.addProduct(productId, itemQuantity);
      } else {
        toggleQuantityModal();
      }
    } else if (update) {
      if (itemQuantity <= maxQuantity) {
        cartContext.updateProductQuantity(productId, itemQuantity);
        setUpdateButton("U PËRDITËSUA ");
      } else {
        toggleQuantityModal();
      }
    }
  };

  const handleQuantityChange = (increment = true) => {
    if (increment && itemQuantity !== maxQuantity) {
      setUpdateButton("PËRDITËSO");
      setItemQuantity((prev) => prev + 1);
    }

    if (!increment && itemQuantity !== 1) {
      setItemQuantity((prev) => prev - 1);
    }
  };

  let checkProduct = () => {
    if (cartContext.items) {
      let filtered = cartContext.items.find((item) => {
        return item?.product?.id === id;
      });
      return filtered;
    }
  };

  const brandImage =
    props.productTags.nodes[0]?.brandPhoto?.brandPhoto?.sourceUrl;

  return (
    <div className="SingleProduct">
      <StockMessageModal
        isOpen={showQuantityModal}
        toggle={toggleQuantityModal}
        closeModal={closeQuantityModal}
        maxQuantity={maxQuantity}
      />
      <PreviewImageModal
        isOpen={showModal}
        toggle={toggle}
        imgSrc={imgSrc}
        closeModal={closeModal}
      />
      <div className="row">
        <div className="col col-md-5 col-12">
          <div className="image-container d-flex justify-content-center align-items-center">
            <img
              onClick={() => handleOpenImage(props.image.sourceUrl)}
              src={props.image?.sourceUrl}
              alt=""
            />
          </div>
        </div>
        <div className="col col-md-7 col-12">
          <div className="product-details-container">
            {props.productTags.nodes.length > 0 && (
              <div className="logo-brand">
                <img src={brandImage} width="50" alt="" />
              </div>
            )}


            <div className="title">
              <h2>{props.name.replace("&amp;", "&")}</h2>
              {props.productDetails.pesha && (
                <p className="weight">
                  <span>Pesha:</span> {props.productDetails.pesha}
                </p>
              )}
            </div>

            <div className="details">
              {props.productDetails.kodiShifra && (
                <p className="">
                  <span>Shifra:</span> {props.productDetails.kodiShifra}
                </p>
              )}

              {props.productBarcode && (
                <p>
                  <span>Barkodi:</span> {props.productBarcode}
                </p>
              )}
              {props.productOrigin.name && (
                <p>
                  <span>Origjina:</span> {props.productOrigin.name}
                </p>
              )}
              {props.productDetails.afati && (
                <p>
                  <span>Afati:</span> {props.productDetails.afati}
                </p>
              )}
              <div className="features d-flex align-items-center ">
                {props.productDetails.productExtraInfo &&
                  props.productDetails.productExtraInfo.map((i) => {
                    return (
                      <div className="product_extra_image">
                        <img src={i.image?.mediaItemUrl} alt="" />
                      </div>
                    );
                  })}
              </div>

              <div className="price-and-buttons">
                {!props.salePrice && (
                  <div className="price_singleProduct">
                    <span className="regular_price">
                      {props.regularPrice?.replace("&nbsp;", "")}
                    </span>
                  </div>
                )}

                {props.salePrice && (
                  <div className="price_singleProduct">
                    <div className="discount_price">
                      <p>{props.regularPrice.replace("&nbsp;", "")}</p>
                    </div>
                    <span className="regular_price">
                      {props.salePrice.replace("&nbsp;", "")}
                    </span>
                  </div>
                )}

                {stockStatus === "IN_STOCK" ? (
                  <div className="product-buttons">
                    <div className="quantity-buttons">
                      <div className="quantity-input">
                        <span>
                          <img
                            onClick={() => handleQuantityChange(false)}
                            className="minus-icon"
                            src={minusIcon}
                            alt=""
                          />
                          <input
                            min="1"
                            type="number"
                            value={itemQuantity}
                            max={maxQuantity}
                            onChange={(e) => setItemQuantity(e.target.value)}
                          />
                          <img
                            onClick={() => handleQuantityChange()}
                            className="plus-icon"
                            src={plusIcon}
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                    {checkProduct() !== undefined ? (
                      <button
                        type="button"
                        className="order-button"
                        onClick={() => handleAddToCart(false, true)}
                      >
                        {updateButton}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="order-button ml-30"
                        onClick={() => handleAddToCart(true, false)}
                      >
                        {newOrderButton}
                      </button>
                    )}
                  </div>
                ) : (
                  <p id="out-of-stock-message"> Out of stock!</p>
                )}
              </div>
            </div>
          </div>
        </div>
        {props.description && (
          <div
            className="SingleProduct__description"
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default SingleProduct;
