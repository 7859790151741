import gql from "graphql-tag";

export const GET_CART = gql`
  query getCart($after: String) {
    cart {
      subtotal
      shippingTotal
      total
      feeTotal
      discountTotal
      isEmpty
      subtotal
      contents(first: 100, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        itemCount
        nodes {
          key
          product {
            id
            productId
            name
            description
            type
            onSale
            slug
            image {
              id
              sourceUrl
            }

            ... on SimpleProduct {
              id
              name
              price
              regularPrice
              stockQuantity
              salePrice
            }
          }
          quantity
          total
          subtotal
          subtotalTax
        }
      }
    }
  }
`;
