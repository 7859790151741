import { gql } from "apollo-boost";

export const GET_CART = gql`
  query getCart {
    cart {
      subtotal
      shippingTotal
      total
      feeTotal
      discountTotal
      isEmpty
      subtotal
      contents(first: 1000) {
        itemCount
        nodes {
          key
          product {
            id
            productId
            name
            description
            type
            onSale
            slug
            image {
              id
              sourceUrl
              altText
            }

            ... on SimpleProduct {
              id
              name
              price
              regularPrice
              salePrice
            }
          }
          quantity
          total
          subtotal
          subtotalTax
        }
      }
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation($input: AddToCartInput!) {
    addToCart(input: $input) {
      cartItem {
        key
        product {
          id
          productId
          name
          description
          type
          onSale
          slug
          averageRating
          reviewCount
          image {
            id
            sourceUrl
            altText
          }
          galleryImages {
            nodes {
              id
              sourceUrl
              altText
            }
          }
          ... on SimpleProduct {
            id
            name
            price
            regularPrice
            salePrice
            defaultAttributes {
              nodes {
                id
                attributeId
                name
                value
              }
            }
          }
        }
        variation {
          id
          variationId
          name
          description
          type
          onSale
          price
          regularPrice
          salePrice
          image {
            id
            sourceUrl
            altText
          }
          attributes {
            nodes {
              id
              attributeId
              name
              value
            }
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
  }
`;

export const UPDATE_ITEM_QUANTITIES = gql`
  mutation($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      items {
        key
        product {
          id
          productId
          name
          description
          type
          onSale
          slug
          averageRating
          reviewCount
          image {
            id
            sourceUrl
            altText
          }
          galleryImages {
            nodes {
              id
              sourceUrl
              altText
            }
          }
          ... on SimpleProduct {
            id
            name
            price
            regularPrice
            salePrice
            defaultAttributes {
              nodes {
                id
                attributeId
                name
                value
              }
            }
          }
        }
        variation {
          id
          variationId
          name
          description
          type
          onSale
          price
          regularPrice
          salePrice
          image {
            id
            sourceUrl
            altText
          }
          attributes {
            nodes {
              id
              attributeId
              name
              value
            }
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
      removed {
        key
        product {
          id
          productId
        }
        variation {
          id
          variationId
        }
      }
      updated {
        key
        product {
          id
          productId
        }
        variation {
          id
          variationId
        }
      }
    }
  }
`;

export const CHECK_CART = gql`
  query checkCart($productId: Int!, $variationId: Int) {
    isInCart(productId: $productId, variationId: $variationId) @client
    getCartItem(productId: $productId, variationId: $variationId) @client
  }
`;
export const GET_CITIES = gql`
  query getCities {
    cities {
      citiesList {
        cities {
          city
          fieldGroupName
          minimumOrder
          shippingMethod
        }
      }
    }
  }
`;
