import gql from "graphql-tag";

export const RELATED_PRODUCTS = gql`
  query getRelatedProductsByCategory($categoryId: [Int], $limit: Int) {
    products(where: { categoryIdIn: $categoryId }, first: $limit) {
      nodes {
        slug
        productId
        id
        name
        image {
          id
          sourceUrl
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
          stockStatus
          stockQuantity
        }
      }
    }
  }
`;

export const GET_RELATED_PRODUCTS = gql`
query getRelatedProducts($productId: [Int]) {
    products(where: { include: $productId}) {
      nodes {
        slug
        productId
        id
        name
        image {
          id
          sourceUrl
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
          stockStatus
          stockQuantity
        }
      }
    }
  }
`;

