import React, { Fragment, useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { searchInput } from "../../actions/uiStateActions";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ThemeSection from "../../components/ThemeSection/ThemeSection";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";
import { loadMoreTexts } from "../../assets/Helpers";
import ProductsLoader from "../../components/ProductSection/ProductsContainer/ProductsLoader";
import ProductTitleLoader from "../../components/ProductSection/ProductSectionTitle/ProductTitleLoader";
import ProductSectionTitle from "../../components/ProductSection/ProductSectionTitle/ProductSectionTitle";
import { ProductCard } from "../../components/ProductCard/ProductCard";
import { SideCart } from "../../components/SideCart/SideCart";
import { SEARCH_QUERY } from "../../queries/searchQuery";
const queryString = require("query-string");

const Search = (props) => {
  const [buttonText, setButtonText] = useState(loadMoreTexts.default),
    limit = 12,
    isArchive = true,
    dispatch = useDispatch(),
    params = queryString.parse(props.location.search),
    searchParam = params.s;

  const { loading, error, data, fetchMore } = useQuery(SEARCH_QUERY, {
    variables: {
      after: null,
      limit: limit,
      search: searchParam,
    },
  });

  useEffect(() => {
    if (searchParam) {
      dispatch(searchInput(searchParam));
    }
  });

  if (error) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <p>Ju lutemi provoni përseri.</p>
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <ProductTitleLoader />
              <ProductsLoader total={limit} />
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  const products = data.products.nodes;

  if (products.length === 0) {
    return (
      <div className="SearchContainer">
        <Container>
          <Fragment>
            <ThemeSection>
              <ProductSectionTitle
                isArchive={isArchive}
                title={"Produkti i kerkuar nuk gjindet në stok"}
              />
            </ThemeSection>
          </Fragment>
        </Container>
      </div>
    );
  }

  const handleLoadMore = () => {
    setButtonText(loadMoreTexts.loading);

    fetchMore({
      variables: {
        after: data.products.pageInfo.endCursor
          ? data.products.pageInfo.endCursor
          : null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (newNodes.length) {
          setButtonText(loadMoreTexts.default);
        }

        return newNodes.length
          ? {
              productCategories: data.productCategories,
              products: {
                __typename: previousResult.products.__typename,
                nodes: [...previousResult.products.nodes, ...newNodes],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  return (
    <div className="SearchContainer">
      <Container>
        <Fragment>
          <ThemeSection>
            <ProductSectionTitle
              isArchive={isArchive}
              title={"Rezultatet e kërkimit për: " + searchParam}
            />
            <div className="row">
              <div className="col col-md-9 col-12">
                <div className="ProductsGrid">
                  <div className="products">
                    {products.map((product) => {
                      return (
                        <ProductCard
                          key={product.productId}
                          product={product}
                        />
                      );
                    })}
                  </div>
                </div>
                {isArchive && data.products.pageInfo.hasNextPage && (
                  <LoadMoreButton
                    title={buttonText}
                    loadMore={handleLoadMore}
                  />
                )}
              </div>
              <div className="col col-md-3 col-12">
                <SideCart />
              </div>
            </div>
          </ThemeSection>
        </Fragment>
      </Container>
    </div>
  );
};

export default Search;
