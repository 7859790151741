import React, { useState } from "react";
import favoriteFull from "../../../assets/images/favorite-full.svg";
import favoriteEmpty from "../../../assets/images/favorite-empty.svg";

import "./FavoriteButton.scss";
import { updateFavorites } from "../../../assets/Helpers";

const FavoriteButton = props => {
  const [isFavorite, toggleFavorite] = useState(props.defaultState);

  const handleFavoriteItem = e => {
    toggleFavorite(!isFavorite);

    updateFavorites(props.id);

    props.onClick(isFavorite);
  };

  return (
    <div className="FavoriteButton">
      <button onClick={e => handleFavoriteItem(e)}>
        <img
          src={isFavorite ? favoriteFull : favoriteEmpty}
          alt="Shto në Listë"
        />
      </button>
    </div>
  );
};

export default FavoriteButton;
