import React, { useState } from "react";
import Container from "reactstrap/es/Container";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { RecipesCategories } from "./RecipesCategories/RecipesCategories";
import { ALL_RECIPES, RECIPES } from "../../queries/recipesQuery";
import RecipesList from "./RecipesList/RecipesList";
import LoadMoreButton from "../../components/LoadMoreButton/LoadMoreButton";

import "./Recipes.scss";
import { Categories } from "../Home/Categories/Categories";

const Recipes = (props) => {
  const [categoryIds, setCategoryIds] = useState(0);

  const changeCategory = async (categoryIds) => {
    setCategoryIds(categoryIds);
  };

  const loadMore = () => {
    fetchMore({
      variables: {
        after: data.posts.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.posts.nodes;
        const pageInfo = fetchMoreResult.posts.pageInfo;

        return newNodes.length
          ? {
              posts: {
                __typename: previousResult.posts.__typename,
                nodes: [...previousResult.posts.nodes, ...newNodes],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  const { data, error, loading, fetchMore } = useQuery(
    categoryIds === 0 ? ALL_RECIPES : RECIPES,
    {
      variables: {
        after: "",
        limit: props.limit ? props.limit : 10,
        categoryIds: categoryIds,
      },
    }
  );

  if (error) {
    return <div>error</div>;
  }

  return (
    <Container>
      {!props.home && !props.singleProduct && (
        <div className="categories_container">
          <RecipesCategories
            recipes={true}
            handleCategoryChange={changeCategory}
            categoryIds={categoryIds}
          />
        </div>
      )}

      <h5 className={props.home ? "recipes_title home" : "recipes_title"}>
        Receta
      </h5>

      {props.showTitle && (
        <p className="single-product-title">
          Receta të ngashme për këtë produkt
        </p>
      )}

      <RecipesList data={data?.posts.nodes} />
      {!props.limit && data?.posts.pageInfo.hasNextPage && (
        <LoadMoreButton loadMore={loadMore} title={"Më shumë"} />
      )}

      {data?.posts.nodes.length <= 0 && (
        <p className="no-recipes-message">Nuk ka receta per kete kategori!</p>
      )}

      {props.home && (
        <div id="button_containeer" className="button_container">
          <Link to="/recetat" className="btn btn-outline load-more-button">
            Më shumë
          </Link>
        </div>
      )}
    </Container>
  );
};

export default Recipes;
