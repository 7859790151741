import React from "react";
import { Link } from "react-router-dom";
import ArrowRight from "../../../assets/icons/arrow-rightt.svg";
import WordpressContent from "../../../components/WordpressContent/WordpressContent";

import "./RecipesList.scss";

const RecipesList = (props) => {
  return (
    <div className="Recipes">
      {props.data?.map((item) => {
        let string = item.excerpt;
        let length = 89;
        let trimmedString = string.substring(0, length);

        return (
          <div className="Recipes_Card">
            <Link to={`/recetat/${item.slug}`} className="image-container">
              <img src={item.featuredImage.sourceUrl} alt="" />
            </Link>
            <div className="content-box">
              <p className="recipe_title">{item.title}</p>
              <WordpressContent
                content={trimmedString + "..."}
                className="WordpressContent--recipes"
              />

              <Link to={`/recetat/${item.slug}`} className="more_recipe">
                <p> Më shumë</p>
                <div>
                  <img className="arrow-rightt" src={ArrowRight} alt="" />
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecipesList;
