import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import Slider from "react-slick";
import prevArrow from "../../../assets/icons/left-cat-arrow.svg";
import nextArrow from "../../../assets/icons/right-cat-arrow.svg";
import { GET_PRODUCTS_MENU } from "../../../queries/categoriesQuery";

import "./RecipesCategories.scss";

export const RecipesCategories = (props) => {
  const [sliderStatus, setSliderStatus] = useState("started");
  const [activeCategory, setActiveCategory] = useState(0);
  const { data, error, loading } = useQuery(GET_PRODUCTS_MENU);

  if (loading || error) {
    return (
      <div className="Categories d-flex flex-row align-items-center"></div>
    );
  }

  if (!data.menus) {
    return (
      <div className="Categories d-flex flex-row align-items-center">
        Nuk ka kategori!
      </div>
    );
  }

  const prevArrowIcon = <img id="arrow_cat" src={prevArrow} />;
  const nextArrowIcon = <img id="arrow_cat" src={nextArrow} />;
  const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToScroll: 1,
    slidesToShow: 5,
    swipe: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 4,
          swipe: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: true,
        },
      },
    ],
    variableWidth: true,
    nextArrow: nextArrowIcon,
    prevArrow: prevArrowIcon,
    afterChange: (index) => {
      if (index > 0) {
        setSliderStatus("slided");
        document.getElementById("cat-slider").classList.add("slided");
      }
      if (index == 0) {
        setSliderStatus("started");
        document.getElementById("cat-slider").classList.add("started");
        document.getElementById("cat-slider").classList.remove("finished");
        document.getElementById("cat-slider").classList.remove("slided");
      }
      if (index === menuItems.length) {
        document.getElementById("cat-slider").classList.add("finished");
        document.getElementById("cat-slider").classList.remove("started");
      }
    },
  };
  const menuItems = data.menus.nodes[0].menuItems.nodes;

  const subItems = menuItems
    .filter((item) => {
      return item.childItems.nodes.length > 0;
    })
    .map((item) => {
      return {
        id: item.connectedObject.productCategoryId,
        list: item.childItems.nodes,
      };
    });

  const handleCategoryClick = (id) => {
    setActiveCategory((prev) => {
      if (prev === id) {
        return 0;
      }
      props.handleCategoryChange(id);
      return id;
    });
  };

  const handleCategoryAll = () => {
    setActiveCategory(0);
    props.handleCategoryChange(0);
  };

  return (
    <div
      className="RecipesCategories__cat-slider-wrapper"
      id="cat-slider"
      data-active-category={activeCategory}
    >
      <span
        className={sliderStatus === "started" ? "cat-right-gradient" : ""}
      ></span>
      <span
        className={
          sliderStatus === "slided" || sliderStatus === "finished"
            ? "cat-left-gradient"
            : ""
        }
      ></span>
      <Slider {...settings}>
        <div className="Recipes__slider">
          <button
            onClick={() => handleCategoryAll()}
            className={activeCategory === 0 ? "cat-list active" : "cat-list"}
          >
            <span className="">Të gjitha</span>
          </button>
        </div>
        {menuItems.map((category) => {
          let categoryId = category.connectedObject.productCategoryId;
          const url = category.connectedObject.image?.mediaItemUrl;

          const urlOnActive =
            category.connectedObject.categoryHoverImage.categoryHoverImage
              .mediaItemUrl;
          return (
            <div
              key={`${categoryId}-main-menu-listing`}
              className="Recipes__slider"
            >
              <button
                onClick={() => handleCategoryClick(categoryId)}
                className={
                  activeCategory === categoryId ? "cat-list active" : "cat-list"
                }
              >
                {category.connectedObject && (
                  <div className="item">
                    <img className="icon" src={url} alt="" />
                    <img className="iconOnActive" src={urlOnActive} alt="" />
                    <span className="">
                      {category.label.replace("&amp;", "&")}
                    </span>
                  </div>
                )}
              </button>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
