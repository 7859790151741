import React, { useState } from "react";
import HomeMainImage from "../../../assets/images/main-image.jpg";
import HomeTopImageMobile from "../../../assets/images/home-top-image.jpg";
import Arrow from "../../../assets/images/arrow-right.svg";
import { useQuery } from "@apollo/react-hooks";
import { SectionSlider } from "../../../components/SectionSlider/SectionSlider";
import { Link } from "react-router-dom";
import FlickitySlider from "../../../components/FlickitySlider/FlickitySlider";
import {
  GET_HOME_INFO,
  GET_HOME_SLIDER,
} from "../../../queries/homeTopSectionQuery";

import "./HomeTopSection.scss";

const isMobile = window.innerWidth < 512;
let HomeTopImage;
if (isMobile) {
  HomeTopImage = HomeTopImageMobile;
} else {
  HomeTopImage = HomeMainImage;
}

export const HomeTopSection = () => {
  const { loading, data, error } = useQuery(GET_HOME_SLIDER);
  const { data: homeInfoData } = useQuery(GET_HOME_INFO);
  const [homeInfo, setHomeInfo] = useState([]);
  const [sliderStatus, setSliderStatus] = useState("started");

  if (loading || error) {
    return <p className="text-center">...</p>;
  }
  const sliderData = data.themeOptions.homePageSlider.sliderItems;

  if (homeInfoData && homeInfo.length === 0) {
    setHomeInfo(homeInfoData.themeOptions.homePageInfo.info);
  }

  return (
    <div className="HomeTopSection">
      <div className="home-top-section__image">
        {sliderData && <SectionSlider data={sliderData} />}
      </div>

      <div className="feature-boxs-row   hidden-sm">
        {homeInfo.map((info, index) => {
          return (
            <div key={index}>
              <div className="feature-box-desktop">
                <img
                  className="feature-box-image"
                  src={info?.photo?.mediaItemUrl}
                  alt=""
                />
                {/*<div className="details-box">*/}
                {/*  <div className="title">{info.title}</div>*/}
                {/*  <div className="subtitle">{info.subtitle}</div>*/}
                {/*  {info.link && (*/}
                {/*    <Link to={info.link} className="more_ds">*/}
                {/*      Më shumë{" "}*/}
                {/*      <img className="arrow-right" src={Arrow} alt="" />*/}
                {/*    </Link>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            </div>
          );
        })}
      </div>
      <div className="row d-flex  feature-boxs-row show-sm">
        <div id="inner-wrapper" className="inner-wrapper">
          <span
            className={
              sliderStatus === "started" ? "right-gradient" : "left-gradient"
            }
          ></span>

          {homeInfo.length > 0 && (
            <FlickitySlider
              options={{
                freeScroll: true,
                contain: true,
                prevNextButtons: false,
                pageDots: false,
              }}
            >
              {homeInfo.map((info) => {
                return (
                  <div className="feature-box">
                    <img src={info?.mobilePhoto?.mediaItemUrl} alt="" />
                    <img src={info?.mobilePhoto?.mediaItemurl} alt="" />
                    <div className="details-box">
                      <div className="title">{info.title}</div>
                      <div className="subtitle">{info.subtitle}</div>
                    </div>
                    {info.link && (
                      <Link to={info.link} className="more-mob">
                        Më shumë{" "}
                        <img className="arrow-right" src={Arrow} alt="" />
                      </Link>
                    )}
                  </div>
                );
              })}
            </FlickitySlider>
          )}
        </div>
      </div>
    </div>
  );
};
