import React, { useState, useEffect } from "react";

import { Form, Alert } from "reactstrap";
import { useMutation } from "react-apollo";

import v4 from "uuid/v4";
import gql from "graphql-tag";
import ReCAPTCHA from "react-google-recaptcha";

import FormModal from "../../../components/FormModal/FormModal";
import FormTitle from "../../../components/FormModal/FormTitle/FormTitle";
import GeneralFormInput from "../../../components/GeneralForm/GeneralFormInput";

import "../ForgotPassword.scss";

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($input: SendPasswordResetEmailInput!) {
    sendPasswordResetEmail(input: $input) {
      clientMutationId
    }
  }
`;

const ResetPasswordEmail = () => {
  const [formData, setFormData] = useState({
    firstname: "",
  });
  const recaptchaRef = React.createRef();
  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const [
    sendPasswordResetEmail,
    { data: resetData, loading: loadingReset, error: resetError },
  ] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  if (loadingReset) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">Ju lutem prisni...</p>
      </FormModal>
    );
  }

  if (resetData) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          Linku për përditësim të fjalëkalimin ju është dërguar në email!
        </p>
      </FormModal>
    );
  }

  if (resetError) {
    return (
      <FormModal halfModal={true}>
        <p className="FormModal__error">
          {resetError.message.replace("GraphQL error: ", "")}
        </p>
      </FormModal>
    );
  }

  const isDisabled = formData.firstname === "" || loadingReset;

  return (
    <FormModal halfModal={true}>
      <FormTitle title="Përditëso fjalëkalimin" />
      <div className="GeneralForm">
        {hasErrors && !captchaToken && (
          <Alert color="warning">
            Ju lutemi plotësoni reCaptcha e mëposhtme!
          </Alert>
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            if (!recaptchaRef) {
              toggleErrors(true);
              return;
            }

            const recaptchaValue = recaptchaRef.current.getValue();

            if (!recaptchaValue) {
              toggleErrors(true);
              return;
            } else {
              toggleErrors(false);
            }

            sendPasswordResetEmail({
              variables: {
                input: {
                  clientMutationId: v4(),
                  username: formData.firstname,
                  captcha: recaptchaValue,
                },
              },
            });
          }}
        >
          <GeneralFormInput
            name="firstname"
            type="text"
            placeholder="Email i perdoruesit"
            onChange={(e) => handleChange(e)}
            value={formData.firstname || ""}
          />
          <div className="GeneralForm__input GeneralForm__input--recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lc2dM4UAAAAADXFx7_Ey4CnEdt3I5P1-2xZen01"
            />
          </div>
          <div
            className={
              "GeneralForm__input" +
              (isDisabled ? " GeneralForm__input--disabled" : "")
            }
          >
            <input
              type="submit"
              disabled={isDisabled}
              value={loadingReset ? "Duke u dërguar" : "Dërgo"}
            />
          </div>
        </Form>
      </div>
    </FormModal>
  );
};

export default ResetPasswordEmail;
