import React from "react";

import "./FormTitle.scss";

const FormTitle = props => {
  return (
    <div className="FormTitle">
      <h2>{props.title}</h2>
    </div>
  );
};

export default FormTitle;
