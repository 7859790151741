import gql from "graphql-tag";

export const GET_PRODUCTS_BY_SLUG = gql`
  query getProductSectionBySlug($slug: String, $limit: Int, $after: String) {
    productCategories(where: { slug: [$slug], search: $slug }) {
      nodes {
        id
        name
      }
    }
    products(where: { category: $slug }, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          name
          slug
          onSale
          salePrice
          regularPrice
        }
      }
    }
  }
`;

export const GET_FILTERED_PRODUCTS = gql`
  query getFilteredProductsQuery(
    $category: String
    $categoryId: Int
    $brandSlug: String
    $limit: Int
    $after: String
  ) {
    products(
      where: { categoryId: $categoryId, category: $category, tag: $brandSlug }
      first: $limit
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          stockQuantity
          name
          slug
          onSale
          salePrice
          regularPrice
          stockStatus
        }
      }
    }
  }
`;
