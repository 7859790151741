import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import Home from "./containers/Home/Home";
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PublicRoute from "./containers/PublicRoute/PublicRoute";
import PrivateRoute from "./containers/PrivateRoute/PrivateRoute";
import { WrappedScrollToTop } from "./containers/WrappedScrollToTop/WrappedScrollToTop";

// Guest
import Login from "./containers/Login/Login";
import RegisterOld from "./containers/Register/Register";
import Checkout from "./containers/Checkout/Checkout";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";

// User
import Orders from "./containers/Orders/Orders";
import Profile from "./containers/Profile/Profile";
import Address from "./containers/Address/Address";
import OrderSingle from "./containers/Orders/OrderSingle/OrderSingle";

// General
import Archive from "./containers/Archive/Archive";
import Favorites from "./containers/Favorites/Favorites";
import AppWrapper from "./containers/AppWrapper/AppWrapper";
import ContentPage from "./containers/ContentPage/ContentPage";
import SearchContainer from "./containers/SearchContainer/SearchContainer";
import SuccessPage from "./containers/SuccessPage/SuccessPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";

import "./styles/_base.scss";
import SingleProductContainer from "./containers/SingleProductContainer/SingleProductContainer";
import GlobalContextProvider from "./contexts/GlobalContext";
import SingleRecipe from "./containers/Recipes/SingleRecipe/SingleRecipe";
import Recipes from "./containers/Recipes/Recipes";
import { CartContextProvider } from "./lib/CartContext/CartContextProvider";
import FailOrder from "./containers/FailOrder/FailOrder";
import { ProductsContextProvider } from "./contexts/ProductsContext/ProductsContextProvider";
import { Categories } from "./containers/Home/Categories/Categories";
import { useResponseDimensions } from "./lib/hooks/useResponseDimensions";

function App() {
  const cookies = new Cookies();
  const isTablet = useResponseDimensions().isTablet;

  return (
    <AppWrapper>
      <BrowserRouter>
        <GlobalContextProvider>
          <ProductsContextProvider>
            <CartContextProvider>
              <WrappedScrollToTop>
                <div id="App-container" className="App">
                  <Header />

                  <main id="main-content" className="main_content">
                    {!isTablet && <Categories />}

                    <Switch>
                      <Route path="/kategoria/:pathParam2?" component={Home} />
                      <Route path="/brendi/:pathParam1?" component={Home} />
                      <PublicRoute path="/kyçu" exact component={Login} />
                      <PublicRoute
                        path="/regjistrohu"
                        exact
                        component={RegisterOld}
                      />
                      <Route
                        path="/perditeso-fjalekalimin"
                        exact
                        component={ForgotPassword}
                      />

                      <Route path="/shporta" exact component={Checkout} />
                      {/*<Route*/}
                      {/*  path="/proceso-porosine"*/}
                      {/*  exact*/}
                      {/*  component={Checkout}*/}
                      {/*/>*/}
                      <Route path="/lista" exact component={Favorites} />
                      <Route
                        path={["/recetat", "/informacione/recetat"]}
                        exact
                        component={Recipes}
                      />
                      <Route
                        path="/recetat/:slug"
                        exact
                        component={SingleRecipe}
                      />

                      <PrivateRoute
                        path="/llogaria"
                        exact
                        component={Profile}
                      />
                      <PrivateRoute path="/adresa" exact component={Address} />
                      <PrivateRoute path="/porosite" exact component={Orders} />
                      <PrivateRoute
                        path="/porosite/:id"
                        exact
                        component={OrderSingle}
                      />

                      <Route path="/produktet/:category/" component={Archive} />
                      <Route
                        path="/produkti/:slug"
                        component={SingleProductContainer}
                      />
                      <Route path="/kerko" exact component={SearchContainer} />

                      <Route
                        path="/porosia-sukses/:slug"
                        exact
                        component={SuccessPage}
                      />
                      <Route
                        path="/porosia-pa-sukses/"
                        exact
                        component={FailOrder}
                      />

                      <Route
                        path="/informacione/:slug"
                        exact
                        component={ContentPage}
                      />
                      <Route path="/" component={Home} />
                    </Switch>
                  </main>
                  <Footer />
                  {!cookies.get("acceptedCookies") && <PrivacyPolicy />}
                </div>
              </WrappedScrollToTop>
            </CartContextProvider>
          </ProductsContextProvider>
        </GlobalContextProvider>
      </BrowserRouter>
    </AppWrapper>
  );
}

export default App;
