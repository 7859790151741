import gql from "graphql-tag";

export const APPLY_COUPON = gql`
  mutation applyCouponMutation($id: String!, $code: String!) {
    applyCoupon(input: { clientMutationId: $id, code: $code }) {
      cart {
        appliedCoupons {
          nodes {
            couponId
            code
            discountType
            amount
            dateExpiry
            discountType
            emailRestrictions
            excludeSaleItems
            maximumAmount
            minimumAmount
          }
        }
      }
    }
  }
`;

export const REMOVE_COUPON = gql`
  mutation removeCouponMutation($id: String!, $code: [String]) {
    removeCoupons(input: { clientMutationId: $id, codes: $code }) {
      cart {
        appliedCoupons {
          nodes {
            couponId
            code
            discountType
            amount
            dateExpiry
            products {
              nodes {
                id
              }
            }
            productCategories {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
`;
