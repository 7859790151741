import gql from "graphql-tag";

export const GET_PRODUCTS_MENU = gql`
  query getProductsMenuQuery {
    menus(where: { slug: "produktet" }) {
      nodes {
        id
        menuItems(first: 100) {
          nodes {
            label
            childItems(first: 100) {
              nodes {
                label
                connectedObject {
                  ... on ProductCategory {
                    slug
                    productCategoryId
                  }
                }
              }
            }
            connectedObject {
              ... on ProductCategory {
                productCategoryId
                image {
                  mediaItemUrl
                }
                categoryHoverImage {
                  categoryHoverImage {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RECIPES_MENU = gql`
  query getProductsMenuQuery {
    menus(where: { slug: "recetat" }) {
      nodes {
        id
        menuItems {
          nodes {
            label
            childItems {
              nodes {
                label
                connectedObject {
                  ... on ProductCategory {
                    productCategoryId
                  }
                }
              }
            }
            connectedObject {
              ... on ProductCategory {
                productCategoryId
                image {
                  mediaItemUrl
                }
                categoryHoverImage {
                  categoryHoverImage {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
