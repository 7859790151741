import gql from "graphql-tag";

export const USER_ADDRESS_DATA = gql`
    query addressQuery($id: ID) {
        customer(id: $id) {
            billing {
                firstName
                lastName
                address1
                address2
                city
                phone
                country
                email
                state
                company
                postcode
            }
            shipping {
                firstName
                lastName
                address1
                address2
                city
                phone
                email
                state
                company
                postcode
            }
        }
    }
`;
