import React, { useContext, createContext } from "react";

const initialValues = {
  selectedCategory: "",
  selectedCategoryId: undefined,
  selectedSubCategoryId: undefined,
  handleCategoryChange: () => {},
  changeBrandPath: () => {},
  changeSubCategoryPath: () => {},
  handleSubCategoryChange: () => {},
  filterProductsWithCategory: () => {},
  changeActiveCategory: () => {},
  loadMore: () => {},
  selectedCategoryName: undefined,
  showCategories: undefined,
  showSubCategories: undefined,
  productsData: [],
  filterAllProducts: () => {},
  activeCategoryId: undefined,
};

export const ProductsContext = createContext(initialValues);
export const useProductsContext = () => useContext(ProductsContext);
