import React, { useState } from "react";
import { ProductCard } from "../../../components/ProductCard/ProductCard";
import { useQuery } from "@apollo/react-hooks";
import { loadMoreTexts } from "../../../assets/Helpers";
import { GET_PRODUCTS_BY_SLUG } from "../../../queries/productsQuery";

import "./ProductsGrid.scss";

export const ProductsGrid = (props) => {
  const [showMoreButtonText, setShowMoreButtonText] = useState(
    loadMoreTexts.default
  );
  const { loading, error, data, fetchMore, refetch } = useQuery(
    GET_PRODUCTS_BY_SLUG,
    {
      variables: {
        after: "",
        slug: props.slug,
        limit: 12,
      },
    }
  );

  if (props.loading || props.productsData === undefined) {
    return (
      <div className="d-flex justify-content-center">
        <div className="loader mt-40"></div>
      </div>
    );
  }

  if (props.error) {
    return (
      <>
        <p>Ju lutemi provoni përseri.</p>
      </>
    );
  }

  const products = props.productsData.products;
  return (
    <div id="ProductsGrid" className="ProductsGrid">
      <div className="products">
        {products.nodes.map((product) => {
          return <ProductCard key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
};
