import React from "react";
import { NavLink } from "react-router-dom";

import "./Order.scss";
import { formatDate } from "../../../assets/Helpers";

const Order = (props) => {
  let type = props.status;
  let statusString;

  switch (type) {
    case "PROCESSING":
      statusString = "Në procesim";
      break;
    case "COMPLETED":
      statusString = "E kompletuar";
      break;
    case "CANCELLED":
      statusString = "E anuluar";
      break;
    case "PENDING":
      statusString = "E pazgjidhur ende";
      break;
    case "REFUNDED":
      statusString = "Rimbursuar";
      break;
    case "FAILED":
      statusString = "Ka dështuar";
      break;
    case "ON_HOLD":
      statusString = "Në pritje";
      break;
    default:
      statusString = "Nuk ka status";
  }

  return (
    <div className="Order">
      <div className="Order__header">
        {props.orderId && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">Porosia:</h4>
            <h4 className="Order__info_value">{props.orderId}</h4>
          </div>
        )}
        {props.status && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">Statusi:</h4>
            <h4 className="Order__info_value">{statusString}</h4>
          </div>
        )}
        {props.date && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">Data e porosisë:</h4>
            <h4 className="Order__info_value">{formatDate(props.date)}</h4>
          </div>
        )}
        {props.dateCompleted && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">Data e dergesës:</h4>
            <h4 className="Order__info_value">
              {formatDate(props.dateCompleted)}
            </h4>
          </div>
        )}
        {props.total && (
          <div className="Order__header__item">
            <h4 className="Order__info_title">Totali:</h4>
            <h4 className="Order__info_value">
              {props.total.replace("&nbsp;", "")}
            </h4>
          </div>
        )}
        <div className="Order__header__item Order__header__item--link">
          <NavLink to={"/porosite/" + props.id} className="Order__info_link">
            Shiko më shumë
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Order;
