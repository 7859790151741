import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { withRouter, Link } from "react-router-dom";
import { addUserInfo } from "../../actions/userActions";
import NavigationButton from "../../components/Buttons/NavigationButton/NavigationButton";
import { Alert, Form } from "reactstrap";
import CheckoutFormInput from "../../components/GeneralForm/CheckoutFormInput";
import { useDispatch } from "react-redux";
import list from "./listOfCities";
import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-datepicker";
// import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  REGISTER_MUTATION,
  LOGIN_MUTATION,
} from "../../queries/mutations/registerMutation";
import "../../styles/general/_form.scss";

import "./Register.scss";

const Register = (props) => {
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const [register, { data, loading, error: registerError }] = useMutation(
    REGISTER_MUTATION
  );
  const [registerType, setRegisterType] = useState("individual");
  const [hasErrors, toggleErrors] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [birthday, setBirthday] = useState("");

  const [registerData, setRegisterData] = useState({
    firstname: "",
    lastname: "",
    company: "",
    municipality: "",
    phone: "",
    country: "XK",
    address: "",
    birthday: "",
    email: "",
    password: "",
    confirmPassword: "",
    fiscal_number: "",
    displayErrorLabel: "",
    isRegistered: false,
  });

  const [
    login,
    { loading: loadingLogin, error: errorLogin, data: dataLogin },
  ] = useMutation(LOGIN_MUTATION);

  const [validPassword, setValidPassword] = useState(true);

  useEffect(() => {
    if (registerError && registerError.message) {
      recaptchaRef.current.props.grecaptcha.reset();
    }
  }, [registerError]);

  useEffect(() => {
    if (hasErrors || !validPassword) {
      recaptchaRef.current.props.grecaptcha.reset();
    }
  }, [hasErrors, validPassword]);

  if (dataLogin && registerData.isRegistered) {
    const userInfo = {
      ID: dataLogin.login.user.id,
      AUTHTOKEN: dataLogin.login.authToken,
      REFRESH_TOKEN: dataLogin.login.refreshToken,
      userId: dataLogin.login.user.userId,
      firstName: dataLogin.login.user.firstName,
      lastName: dataLogin.login.user.lastName,
    };

    localStorage.setItem("BEGROSS_USER", JSON.stringify(userInfo));

    dispatch(
      addUserInfo({
        id: dataLogin.login.user.id,
        isLogged: true,
        firstName: dataLogin.login.user.firstName,
        lastName: dataLogin.login.user.lastName,
        userId: dataLogin.login.user.userId,
      })
    );
    props.history.replace("/");
  }

  if (data && !registerData.isRegistered) {
    try {
      login({
        variables: {
          username: registerData.email,
          password: registerData.password,
          captcha: captchaToken,
        },
      });
    } catch (e) {}

    setRegisterData({
      ...registerData,
      isRegistered: true,
    });

    return (
      <div className="GeneralForm">
        <div className="Register--success">
          <h1>Mire se vini ne platformën Begmart</h1>
          <h4>Ju jeni regjistruar me sukses!</h4>
          <NavigationButton to="/" title="Vazhdo me blerjen" />
        </div>
      </div>
    );
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.target.className = "form-control";
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleOnBlur = (e) => {
    if (e.target.value + "" === "") {
      e.target.className = "form-control error";
    }
  };

  const changeType = (type) => {
    setRegisterType(type);
  };

  const handleSubmitForm = (e) => {
    setRegisterData({
      ...registerData,
      displayErrorLabel: "",
    });

    try {
      const {
        firstname,
        lastname,
        company,
        municipality,
        address,
        password,
        telephone,
        email,
        country,
        fiscal_number,
      } = registerData;

      register({
        variables: {
          firstname: firstname,
          address,
          password,
          telephone,
          email,
          country,
          lastname,
          company,
          municipality,
          fiscal_number,
          birthday: birthday,
          captcha: captchaToken,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container Register">
      <div className="row mt-30 mb-100 d-flex justify-content-center">
        <div className="col d-flex flex-column justify-content-center form-container">
          {registerData.displayErrorLabel !== "" && (
            <p className="error text-center">
              {registerData.displayErrorLabel}
            </p>
          )}
          {data ? (
            <p className="sucess">Jeni regjistruar me sukses.</p>
          ) : (
            <>
              <div className="row mt-50 top-text">
                <div className="col d-flex flex-column justify-content-center">
                  <h1 className="text-center">Krijo llogarinë tuaj</h1>
                  <p className="text-center">
                    Nëse e keni, <Link to="/kyçu">kyçu</Link>.
                  </p>
                </div>
              </div>
              {hasErrors && (
                <Alert color="warning">
                  Si duket ka gabime në formë! Ju lutemi provoni përseri
                </Alert>
              )}

              {registerError?.message.includes(
                "An account is already registered with your email address. Please log in."
              ) && (
                <Alert color="warning">
                  Një përdorues është i regjistruar tashmë me këtë email. Ju
                  lutem kyçuni!
                </Alert>
              )}

              {hasErrors && !captchaToken && (
                <Alert color="warning">
                  Ju lutemi plotësoni reCaptcha e mëposhtme!
                </Alert>
              )}
              {registerType === "biznes" &&
                hasErrors &&
                registerData.fiscal_number === "" && (
                  <Alert color="warning">
                    Ju lutemi plotësoni Numrin fiskal!
                  </Alert>
                )}
              <Form
                className="register-form mt-30"
                onSubmit={async (e) => {
                  e.preventDefault();

                  if (!recaptchaRef) {
                    toggleErrors(true);
                    return;
                  }

                  const recaptchaValue = recaptchaRef.current.getValue();

                  if (!recaptchaValue) {
                    toggleErrors(true);
                    return;
                  } else {
                    toggleErrors(false);
                  }

                  if (
                    (!registerData.fiscal_number ||
                      registerData.fiscal_number === "") &&
                    registerType === "biznes"
                  ) {
                    toggleErrors(true);
                    return;
                  } else {
                    toggleErrors(false);
                  }

                  setCaptchaToken(recaptchaValue);

                  handleSubmitForm(e);
                }}
              >
                <div className="row d-flex justify-content-center mb-50">
                  <div className="col d-flex justify-content-center">
                    <div
                      className={`btn btn-outline ${
                        registerType === "individual" ? "active" : ""
                      }`}
                      onClick={() => changeType("individual")}
                    >
                      Individual
                    </div>
                    <div
                      className={`btn btn-outline ml-15 ${
                        registerType === "biznes" ? "active" : ""
                      }`}
                      onClick={() => changeType("biznes")}
                    >
                      Biznes
                    </div>
                  </div>
                </div>

                {registerType === "individual" && (
                  <div className="row">
                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="1"
                        name="firstname"
                        type="text"
                        placeholder="Emri"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="2"
                        type="text"
                        name="lastname"
                        placeholder="Mbiemri"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="3"
                        id="address_input"
                        type="text"
                        name="address"
                        placeholder="Adresa"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <select
                        tabIndex="4"
                        id="city"
                        name="municipality"
                        className="form-control select-city-register"
                        onChange={handleChange}
                      >
                        <option id="city-placeholder" value="">
                          {" "}
                          Qyteti{" "}
                        </option>

                        {list.map((city, index) => (
                          <option key={index}>{city.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="5"
                        type="text"
                        name="country"
                        placeholder="Shteti"
                        value={"Kosovë"}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                        disabled={"disabled"}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="6"
                        type="text"
                        name="telephone"
                        required
                        placeholder="Telefoni"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <div className="datepicker-container">
                        <DatePicker
                          placeholderText="Ditëlindja (dd/mm/yyyy) "
                          id="react-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selected={birthday}
                          onChange={(date) => {
                            setBirthday(date);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="8"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="9"
                        type="password"
                        name="password"
                        placeholder="Fjalëkalimi"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        tabIndex="10"
                        type="password"
                        name="confirm_password"
                        placeholder="Konfirmo fjalëkalimin"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    {!validPassword && (
                      <div className="col col-12">
                        <Alert color="warning">
                          Fjalëkalimi duhet të përmbajë së paku 8 karaktere dhe
                          një numër!
                        </Alert>
                      </div>
                    )}
                  </div>
                )}

                {registerType === "biznes" && (
                  <div className="row">
                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        name="firstname"
                        type="text"
                        placeholder="Emri"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="text"
                        name="lastname"
                        placeholder="Mbiemri"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        id="address_input"
                        type="text"
                        name="address"
                        placeholder="Adresa"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <select
                        id="city"
                        name="municipality"
                        className="form-control"
                        onChange={handleChange}
                      >
                        <option id="city-placeholder" value="">
                          {" "}
                          Qyteti{" "}
                        </option>
                        {list.map((city, index) => (
                          <option key={index}>{city.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="text"
                        name="country"
                        placeholder="Shteti"
                        value={"Kosovë"}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                        disabled={"disabled"}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="text"
                        name="company"
                        placeholder="Emri i kompanisë"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="text"
                        name="fiscal_number"
                        placeholder="Nr.Fiskal/NUI"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleOnBlur(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="password"
                        name="password"
                        placeholder="Fjalëkalimi"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                      <CheckoutFormInput
                        type="password"
                        name="confirm_password"
                        placeholder="Konfirmo fjalëkalimin"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    {!validPassword && (
                      <div className="col col-12">
                        <Alert color="warning">
                          Fjalëkalimi duhet të përmbajë së paku 8 karaktere dhe
                          një numër!
                        </Alert>
                      </div>
                    )}
                  </div>
                )}
                <div className="terms_link">
                  <input type="checkbox" />
                  <p>
                    Unë pranoj{" "}
                    <Link className="link" to="/">
                      Termet dhe Kushtet
                    </Link>{" "}
                    e kompanisë
                  </p>
                </div>
                <div className="row mt-20">
                  <div className="col d-flex justify-content-center">
                    <div className="GeneralForm__input GeneralForm__input--recaptcha">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lc2dM4UAAAAADXFx7_Ey4CnEdt3I5P1-2xZen01"
                        onChange={(e) => {
                          setCaptchaToken(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="GeneralForm__input text-center mt-20">
                  <div className="d-flex justify-content-center align-items-center">
                    <input value="Regjistrohu" type="submit" />
                    {loading && <div className="small-loader ml-15"></div>}
                  </div>
                </div>

                {/*<div className='line'></div>*/}
                {/*<div className='social_login'>*/}
                {/*    <Link id='link' to='/'>*/}
                {/*        <img src={facebookLogin} alt=""/>*/}
                {/*    </Link>*/}
                {/*    <Link id='link' to='/'>*/}
                {/*        <img src={googleLogin} alt=""/>*/}
                {/*    </Link>*/}
                {/*</div>*/}
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Register);
