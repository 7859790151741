import { combineReducers } from "redux";

import cartReducer from "./cartReducer";
import userReducer from "./userReducer";
import uiStateReducer from "./uiStateReducer";

const rootReducers = combineReducers({
  cart: cartReducer,
  user: userReducer,
  uiState: uiStateReducer,
});

export default rootReducers;
