import React, { useState } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import { addProductTexts } from "../../../assets/Helpers";
import QuanitityInput from "../../QuantityInput/QuantityInput";
import ProductSectionButton from "../../Buttons/ProductSectionButton/ProductSectionButton";

import FavoriteButton from "../../../components/Buttons/FavoriteButton/FavoriteButton";
import RemoveFavoriteButton from "../../Buttons/RemoveFavoriteButton/RemoveFavoriteButton";

import "./ProductItem.scss";
import ProductItemThumb from "./ProductItemThumb";

const ProductItem = (props) => {
  const [options, showOptions] = useState(false),
    [cartQuantity, updateQuantity] = useState(1),
    [buttonClass, setButtonClass] = useState("default"),
    [showFavoriteText, setShowFavoriteText] = useState(false),
    [favoriteText, setFavoriteText] = useState(),
    [buttonText, setButtonText] = useState(addProductTexts.default);
  const { flag: flagImage, name: flagName } = props.productOrigin;

  const priceClasses = classnames({
    product_price: true,
    "product_price--sale": props.onSale,
  });

  const handleButtonSuccess = () => {
    setButtonClass("success");
    setButtonText(addProductTexts.success);
  };

  const handleQuantityUpdate = (value) => {
    updateQuantity(value);

    if (buttonClass === "default") {
      return;
    }

    setButtonClass("update");
    setButtonText(addProductTexts.update);
  };

  const handleFavoriteToggle = (value) => {
    setShowFavoriteText(true);
    setFavoriteText(
      !value ? "Produkti u shtua në listë" : "Produkti u largua nga lista"
    );

    setTimeout(() => {
      setShowFavoriteText(false);
    }, 2000);
  };

  return (
    <div className="ProductItem">
      <div className="ProductItem__thumb">
        {props.image && (
          <ProductItemThumb alt={props.name} src={props.image.sourceUrl} />
        )}
      </div>
      <div className="ProductItem__overlay">
        <div className="ProductItem__info_box ProductItem__more">
          <div className="ProductItem__info_left">
            {props.isFavorites ? (
              <RemoveFavoriteButton
                id={props.productId}
                onClick={props.onRemove}
              />
            ) : (
              <FavoriteButton
                id={props.productId}
                defaultState={props.isFavorite}
                onClick={(value) => handleFavoriteToggle(value)}
              />
            )}
          </div>
          <div className="ProductItem__info_right">
            <NavLink to={"/produkti/" + props.slug}>Detaje</NavLink>
          </div>
          {showFavoriteText && (
            <h4 className="ProductItem__favorite_text">{favoriteText}</h4>
          )}
        </div>
      </div>
      {flagName && flagImage && (
        <div className="ProductItem__flag">
          <img src={flagImage} alt={flagName} title={flagName} />
        </div>
      )}
      <div className={priceClasses}>
        {props.regularPrice && (
          <h4>{props.regularPrice.replace("&nbsp;", "")}</h4>
        )}
        {props.onSale && (
          <h4 className="sale_price">
            {props.salePrice.replace("&nbsp;", "")}
          </h4>
        )}
      </div>
      <h3 className="ProductItem__title">{props.name.replace("&amp;", "&")}</h3>
      <div className="ProductItem__info_box ProductItem__cart_info">
        <button
          onClick={() => showOptions(true)}
          className="ProductItem__info_left ProductItem__cart_toggle"
        ></button>
        {options && (
          <div className="ProductItem__info_right ProductItem__quantity">
            <QuanitityInput
              quantity={cartQuantity}
              onChange={(value) => handleQuantityUpdate(value)}
              smallBox
            />
          </div>
        )}
      </div>
      {options && (
        <ProductSectionButton
          productAttributes={{
            quantity: cartQuantity,
            id: props.productId,
          }}
          updateStatus={() => {
            handleButtonSuccess();
          }}
          buttonClass={buttonClass}
          title={buttonText}
        />
      )}
    </div>
  );
};

export default ProductItem;
