import React from "react";
import "./ProductSectionButton.scss";
import { useDispatch } from "react-redux";
import useCartMutations from "../../../useCartMutation";
import { addIdToCart } from "../../../actions/cartActions";

const ProductSectionButton = (props) => {
  const {
    productAttributes: { id = 0, quantity = 1 },
  } = props;
  const dispatch = useDispatch();

  const { addToCart } = useCartMutations(id, null);

  const handleCartUpdate = (e) => {
    e.preventDefault();
    addToCart(id, quantity, null, null, {});
    dispatch(addIdToCart(id));
    props.updateStatus();
  };

  return (
    <button
      onClick={(e) => handleCartUpdate(e)}
      className={
        "ProductSectionButton ProductSectionButton--" + props.buttonClass
      }
    >
      {props.title}
    </button>
  );
};

export default ProductSectionButton;
