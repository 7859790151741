import gql from "graphql-tag";

export const PAYMENT_METHODS = gql`
  query PaymentMethods {
    paymentGateways {
      nodes {
        id
        description
        title
      }
    }
  }
`;
