import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gql from "graphql-tag";
import { localUser, isTokenExpired } from "../../assets/Helpers";
import { addUserInfo } from "../../actions/userActions";
import { useQuery } from "@apollo/react-hooks";
import { logoutUser } from "../../actions/userActions";

const USER_INFO = gql`
  query userInfo($userID: ID!) {
    user(id: $userID) {
      id
      email
      userId
      firstName
      lastName
    }
  }
`;

const AppWrapper = (props) => {
  const localUserInfo = localUser(),
    authToken = localUserInfo.AUTHTOKEN;
  const dispatch = useDispatch();

  const userInfo = useQuery(USER_INFO, {
    variables: {
      userID: localUserInfo.ID,
    },
  });

  if (localUserInfo && isTokenExpired(authToken)) {
    localStorage.removeItem("BEGROSS_USER");
    localStorage.removeItem("woo-session");
    dispatch(logoutUser());
  }

  useEffect(() => {
    if (userInfo && userInfo.data) {
      dispatch(
        addUserInfo({
          isLogged: true,
          ...userInfo.data.user,
        })
      );
    }
  });

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AppWrapper;
