import React from "react";
import { NavLink } from "react-router-dom";

import "./Logo.scss";
import logo from "../../../assets/images/logo-begross.svg";

const Logo = props => {
  return (
    <div className={"Logo" + (props.white ? " Logo--white" : "")}>
      <NavLink to="/">
        <img src={logo} alt="Begmart" />
      </NavLink>
    </div>
  );
};

export default Logo;
