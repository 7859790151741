import gql from "graphql-tag";

export const ALL_RECIPES = gql`
  query getAllRecipes($limit: Int, $after: String) {
    posts(first: $limit, after: $after) {
      nodes {
        excerpt(format: RENDERED)
        slug
        title
        id
        featuredImage {
          sourceUrl
          id
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const RECIPES = gql`
  query Recipes($limit: Int, $after: String, $categoryIds: [String]) {
    posts(
      first: $limit
      after: $after
      where: {
        taxQuery: {
          taxArray: {
            taxonomy: PRODUCTCATEGORY
            terms: $categoryIds
            field: TAXONOMY_ID
          }
        }
      }
    ) {
      nodes {
        excerpt(format: RENDERED)
        slug
        title
        id
        featuredImage {
          sourceUrl
          id
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_SINGLE_RECIPE = gql`
  query getSingleRecipe($slug: String) {
    posts(where: { name: $slug }) {
      edges {
        node {
          id
          title
          content(format: RENDERED)
          featuredImage {
            id
            sourceUrl(size: LARGE)
          }
           relatedProductsIds
        }
      }
    }
  }
`;
