import React from "react";
import cs from "classnames";

import "./WordpressContent.scss";

const WordpressContent = (props) => {
  const { content } = props;
  return (
    <div
      className={cs("WordpressContent", props.className)}
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default WordpressContent;
