import gql from "graphql-tag";

export const GET_MENUS = gql`
  query getMenu {
    menus(where: { slug: "main" }) {
      nodes {
        menuItems {
          nodes {
            cssClasses
            label
            connectedObject {
              ... on Page {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_HOME_LOGO = gql`
  query getHeaderData {
    themeOptions {
      homePageSlider {
        logo {
          id
          mediaItemUrl
        }
      }
      general {
        email
        phone
        paymentMethodImage {
          mediaItemUrl
        }
      }
    }
  }
`;
