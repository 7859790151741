import React from "react";
import { Redirect } from "react-router";
import { localUser, isTokenExpired } from "../../assets/Helpers";

const PrivateRoute = props => {
  const Component = props.component;
  const localUserInfo = localUser();

  if (!localUserInfo || isTokenExpired(localUserInfo.AUTHTOKEN)) {
    //redirect to login
    return <Redirect to="/kyqu" />;
  }

  return <Component />;
};

export default PrivateRoute;
