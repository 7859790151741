import React from "react";
import classnames from "classnames";

import "./FormModal.scss";

const FormModal = props => {
  const classes = classnames({
    FormModal: true,
    "FormModal--sub": props.subModal,
    "FormModal--login": props.halfModal,
    "FormModal--centered": props.centered
  });
  return <div className={classes}>{props.children}</div>;
};

export default FormModal;
