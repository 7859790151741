export const addUserInfo = (info) => {
  return {
    type: "ADD_USER_INFO",
    payload: info,
  };
};

export const updateFavoriteItems = (list) => {
  return {
    type: "UPDATE_FAVORITES",
    payload: list,
  };
};
export const logoutUser = () => {
  localStorage.removeItem("BEGROSS_USER");
  localStorage.removeItem("woo-session");
  return {
    type: "LOG_OUT_USER",
  };
};
