import React, { Component, useContext } from "react";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";

class ScrollToTop extends Component {
  static contextType = GlobalContext;

  componentDidUpdate(prevProps) {
    this.handleViewScroll(prevProps.location.pathname);
  }

  handleViewScroll(prevPath) {
    const path = this.props.location.pathname;

    if (path === prevPath) {
      return;
    }

    if (path.startsWith("/kategoria/") || path.startsWith("/brendi")) {
      const offset = 120;

      const el = document.getElementById("main");
      window.scroll({
        top: el.offsetTop - offset,
        left: 0,
        behavior: "smooth",
      });
      return;
    }

    window.scrollTo(0, 0);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

export const WrappedScrollToTop = withRouter(ScrollToTop);
