import gql from "graphql-tag";

export const SEARCH_QUERY = gql`
  query ProductSearch($limit: Int, $search: String, $after: String) {
    products(where: { search: $search }, first: $limit, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        productId
        productOrigin {
          flag
          name
        }
        ... on SimpleProduct {
          id
          image {
            id
            sourceUrl(size: MEDIUM)
          }
          stockStatus
          stockQuantity
          name
          slug
          onSale
          salePrice
          regularPrice
        }
      }
    }
  }
`;
