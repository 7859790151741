export const toggleNav = () => {
  return {
    type: "TOGGLE_NAV"
  };
};

export const closeNav = () => {
  return {
    type: "CLOSE_NAV"
  };
};

export const searchInput = value => {
  return {
    type: "FILL_SEARCH_VALUE",
    payload: value
  };
};
