import React from "react";
import { Container } from "reactstrap";
import UserSidebar from "./UserSidebar/UserSidebar";

import "./UserSettings.scss";

const UserSettings = (props) => {
  return (
    <Container>
      <div className="UserSettings">
        <UserSidebar />
        <div className="UserSettings__content">{props.children}</div>
      </div>
    </Container>
  );
};

export default UserSettings;
