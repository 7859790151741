import gql from "graphql-tag";

export const HOME_BANNERS = gql`
  query getHomeBanners {
    themeOptions {
      homePageBanners {
        banner1Link
        banner2Link
        fieldGroupName
        banner1 {
          mediaItemUrl
        }
        banner2 {
          mediaItemUrl
        }
      }
    }
  }
`;
