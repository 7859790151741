import React from "react";
import { Container } from "reactstrap";
import RelatedProducts from "../../RelatedProducts/RelatedProducts";
import { useQuery } from "@apollo/react-hooks";
import LatestRecipes from "./LatestRecipes/LatestRecipess";
import WordpressContent from "../../../components/WordpressContent/WordpressContent";
import { GET_SINGLE_RECIPE } from "../../../queries/recipesQuery";

import "./SingleRecipe.scss";

const SingleRecipe = (props) => {
  const slug = props.match.params.slug;

  const { data, loading, error } = useQuery(GET_SINGLE_RECIPE, {
    variables: {
      slug: slug,
    },
  });

  //TODO: loader
  if (loading) {
    return <div></div>;
  }

  if (error) {
    return <div>error: {error.message}</div>;
  }

  const relatedProductsIds = data.posts.edges[0].node.relatedProductsIds;
  const featuredImage = data.posts.edges[0].node.featuredImage.sourceUrl;
  const title = data.posts.edges[0].node.title;
  const content = data.posts.edges[0].node.content;


  return (
    <div className="SingleReceipt">
      <Container>
        <div className="columns">
          <div className="column-1">
            <p className="SingleRecipe_title">{title}</p>
            <article className="singleRecipe">
              {/* FEATURED IMAGE */}
              <div className="featured-image">
                <img src={featuredImage} alt="" />
              </div>
              {/* /FEATURED IMAGE */}

              {/* CONTENT */}

              <WordpressContent
                content={content}
                className="WordpressContent"
              />

              {/* /CONTENT */}
            </article>
          </div>
          <div className="column-2">
            <LatestRecipes />
          </div>
        </div>

        {/*RELATED PRODUCTS */}
        {relatedProductsIds.length >= 1 && (
          <div className="RelatedProducts-Container">
            <h5 className="relatedProducts_title">
              Produkte që ju nevojiten për këtë recetë
            </h5>
            <RelatedProducts
              singleRecipe={true}
              limit={8}
              relatedProductsIds={relatedProductsIds}
            />
          </div>
        )}
        {/*RELATED PRODUCTS */}
      </Container>
    </div>
  );
};

export default SingleRecipe;
