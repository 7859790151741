import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useMutation, useQuery } from "@apollo/react-hooks";
import v4 from "uuid/v4";
import {
  APPLY_COUPON,
  REMOVE_COUPON,
} from "../../../queries/mutations/couponMutation";
import { PAYMENT_METHODS } from "../../../queries/paymentQuery";
import { useCartContext } from "../../../lib/CartContext/CartContext";

import "./PaymentData.scss";

const PaymentData = (props) => {
  const cartContext = useCartContext();
  const [couponCode, setCouponCode] = useState(" ");
  const [payment, setPayment] = useState({
    selectedPaymentMethod: "",
  });

  const [
    applyCouponMethod,
    { data: couponData, loading: couponLoading, error: couponError },
  ] = useMutation(APPLY_COUPON);
  const couponCodeLocal = localStorage.getItem("couponCode");
  const couponErrorMessage = couponError?.graphQLErrors[0]?.message;

  const [
    removeCouponMethod,
    {
      data: removeCouponData,
      loading: loadingRemoveCoupon,
      error: errorRemoveCoupon,
    },
  ] = useMutation(REMOVE_COUPON);

  const { loading, data: methods } = useQuery(PAYMENT_METHODS);

  if (loading) {
    return <div className="Payment--loading" />;
  }

  if (couponLoading) {
    return <div>loading...</div>;
  }

  const dataAfterRemove = removeCouponData;
  const appliedCoupons = couponData?.applyCoupon?.cart?.appliedCoupons?.nodes;

  const handleChange = (itemId) => {
    setPayment({
      ...payment,
      selectedPaymentMethod: itemId,
    });
    props.onChange(itemId);
  };

  const onCouponApply = () => {
    applyCouponMethod({
      variables: {
        id: v4(),
        code: couponCode,
      },
    }).then(() => {
      localStorage.setItem("couponCode", couponCode);
      cartContext.refetch();
    });
  };

  const onCouponRemove = () => {
    removeCouponMethod({
      variables: {
        id: v4(),
        code: couponCode,
      },
    }).then(() => {
      cartContext.refetch();
      localStorage.removeItem("couponCode");
    });
  };

  const paymentMethods = methods.paymentGateways.nodes;
  const minCouponPrice = couponErrorMessage?.split(`">`);
  const exProducts = couponErrorMessage?.split(`products:`)[1];
  const excludeProducts = exProducts && exProducts.replace("&amp;", "&");
  const excludeCategories = couponErrorMessage?.split(`categories:`)[1];

  return (
    <div className="PaymentData">
      <div className="row">
        <div className="col-md-8 m-0">
          <div className="base-info-container d-flex flex-column">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-6">
                <p className="payment-title">Mënyra e pagesës</p>

                {paymentMethods.map((item) => {
                  return (
                    <FormGroup key={item.id} check>
                      <div>
                        <Input
                          className="payment-checkbox"
                          type="radio"
                          id={item.id}
                          name="payment"
                          required
                          label={item.title}
                          onChange={(e) => {
                            handleChange(item.id);
                          }}
                        />
                        <span className="payment-label">{item.title}</span>
                        {item.id === "other_payment" &&
                          payment.selectedPaymentMethod === "other_payment" && (
                            <Input
                              className="payment-checkbox"
                              type="text"
                              id="pos_payment_type"
                              name="pos_payment_type"
                              required
                              placeholder="Shkuani emrin e Bankës *"
                              onChange={(e) => {
                                props.onChangeType(e.target.value);
                              }}
                            />
                          )}
                      </div>
                    </FormGroup>
                  );
                })}
              </div>
              <div className="PaymentData__coupon-container col-12 col-sm-12 col-md-6">
                <p className="payment-title">A keni kupon të zbritjes?</p>
                <Input
                  placeholder="Shkuani kodin këtu"
                  className="PaymentData__apply-input"
                  {...props}
                  onChange={(e) => {
                    setCouponCode(e.target.value.toLowerCase());
                  }}
                />
                {appliedCoupons &&
                appliedCoupons[0].code === couponCodeLocal ? (
                  <p className="PaymentData__success-message">
                    Zbritja me kodin
                    <span className="PaymentData__code-text">{couponCode}</span>
                    është aplikuar.
                    <span
                      className="PaymentData__remove-button"
                      onClick={() => onCouponRemove()}
                    >
                      Largo
                    </span>
                  </p>
                ) : null}
                {couponError &&
                  couponErrorMessage === "This coupon has expired." && (
                    <p className="PaymentData__error-message">
                      Kodi qe keni shkruar ka skaduar!
                    </p>
                  )}

                {couponError &&
                  couponErrorMessage.includes("does not exist!") && (
                    <p className="PaymentData__error-message">
                      Kodi qe keni shkruar nuk ekziston!
                    </p>
                  )}
                {couponError &&
                  couponErrorMessage.includes(
                    "Sorry, this coupon is not applicable to the categories"
                  ) && (
                    <p className="PaymentData__error-message">
                      Na vjen keq ky kupon nuk mund te aplikohet nese keni ne
                      kartë këto kategori: {excludeCategories}
                    </p>
                  )}

                {couponError &&
                  couponErrorMessage.includes(
                    "Sorry, this coupon is not applicable to the products"
                  ) && (
                    <p className="PaymentData__error-message">
                      Na vjen keq ky kupon nuk mund te aplikohet nese keni ne
                      kartë këto produkte: {excludeProducts}
                    </p>
                  )}

                {couponError &&
                  couponErrorMessage.includes(
                    "The minimum spend for this coupon"
                  ) && (
                    <p className="PaymentData__error-message">
                      Cmimi minimal per ta aplikuar këtë kupon është{" "}
                      {minCouponPrice[1].split(`&nbsp`)[0]}€
                    </p>
                  )}
                {couponError &&
                  couponErrorMessage.includes(
                    "The maximum spend for this coupon"
                  ) && (
                    <p className="PaymentData__error-message">
                      Cmimi maksimal per ta aplikuar këtë kupon është{" "}
                      {minCouponPrice[1].split(`&nbsp`)[0]}€
                    </p>
                  )}
                {couponError &&
                  couponErrorMessage.includes(
                    "This coupon has already been applied to the cart"
                  ) && (
                    <p className="PaymentData__error-message">
                      Kodi qe keni shkruar tashmë është aplikuar
                    </p>
                  )}
                <div className="PaymentData__button-container">
                  <button
                    onClick={onCouponApply}
                    className="PaymentData__apply-button"
                  >
                    Apliko
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentData;
