import React, { useState } from "react";
import { Row, Col, Form } from "reactstrap";
import { useQuery, useMutation } from "react-apollo";
import UserSettings from "../../components/UserSettings/UserSettings";
import BillingDetails from "../../components/CheckoutForms/BillingDetails/BillingDetails";
import ShippingDetails from "../../components/CheckoutForms/ShippingDetails/ShippingDetails";
import v4 from "uuid/v4";
import UserSettingsTitle from "../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";
import { localUser } from "../../assets/Helpers";
import { USER_ADDRESS_DATA } from "../../queries/userAdressQuery";

import "../../styles/general/_form.scss";
import { UPDATE_ADDRESS_MUTATION } from "../../queries/mutations/userAddressMutation";

const Address = () => {
  const userInfo = localUser();

  const [addressData, setAddressData] = useState({
    billing: {
      firstName: "",
      lastName: "",
      company: "",
      city: "",
      address: "",
      country: "XK",
      apartment: "",
      telephone: "",
      email: "",
      isLoaded: false,
    },
    shipping: {
      firstName: "",
      lastName: "",
      company: "",
      city: "",
      address: "",
      apartment: "",
      telephone: "",
      email: "",
      comment: "",
      differentShipping: true,
      isLoaded: false,
    },
    isValid: false,
  });

  const [
    address,
    { data: mutationData, loading: mutationLoading },
  ] = useMutation(UPDATE_ADDRESS_MUTATION);

  const {
    loading: loadingBilling,
    error: errorBilling,
    data: dataBilling,
  } = useQuery(USER_ADDRESS_DATA, {
    variables: {
      id: userInfo.ID,
    },
  });

  if (!addressData.billing.isLoading && dataBilling) {
    const billingInfo = dataBilling.customer.billing;
    if (billingInfo) {
      setAddressData({
        ...addressData,
        billing: {
          ...addressData.billing,
          firstName: billingInfo.firstName,
          lastName: billingInfo.lastName,
          company: billingInfo.company,
          city: billingInfo.city,
          country: billingInfo.country,
          address: billingInfo.address1,
          apartment: billingInfo.address2,
          telephone: billingInfo.phone,
          email: billingInfo.email,
          isLoading: true,
        },
      });
    }
  }

  if (!addressData.shipping.isLoading && dataBilling) {
    const shippingInfo = dataBilling.customer.shipping;
    if (shippingInfo) {
      setAddressData({
        ...addressData,
        shipping: {
          ...addressData.shipping,
          firstName: shippingInfo.firstName,
          lastName: shippingInfo.lastName,
          company: shippingInfo.company,
          city: shippingInfo.city,
          address: shippingInfo.address1,
          apartment: shippingInfo.address2,
          telephone: shippingInfo.phone,
          email: shippingInfo.email,
          isLoading: true,
        },
      });
    }
  }

  const handleBillingChange = (name, value) => {
    setAddressData({
      ...addressData,
      billing: {
        ...addressData.billing,
        [name]: value,
      },
    });
  };

  const handleShippingChange = (name, value) => {
    setAddressData({
      ...addressData,
      shipping: {
        ...addressData.shipping,
        [name]: value,
      },
    });
  };

  const handleCheckoutSubmit = (e) => {
    e.preventDefault();

    const { billing, shipping } = addressData;
    address({
      variables: {
        input: {
          clientMutationId: v4(),
          id: userInfo.ID,
          billing: {
            firstName: billing.firstName,
            lastName: billing.lastName,
            phone: billing.telephone,
            address1: billing.address,
            address2: billing.apartment,
            company: billing.company,
            state: "AL",
            country: billing.country,
            city: billing.city,
            email: billing.email,
          },
          shipping: {
            firstName: shipping.firstName,
            lastName: shipping.lastName,
            address1: shipping.address,
            address2: shipping.apartment,
            company: shipping.company,
            state: "XK",
            country: "XK",
            city: shipping.city,
          },
        },
      },
    });
  };

  return (
    <div className="Profile">
      <UserSettings>
        <UserSettingsTitle title="Perditëso Adresat" />
        <Form
          onSubmit={(e) => {
            handleCheckoutSubmit(e);
          }}
        >
          <Row>
            <Col xs="12" sm="12" lg="6">
              <BillingDetails
                details={addressData.billing}
                onChange={(name, value) => handleBillingChange(name, value)}
              />
            </Col>
            <Col xs="12" sm="12" lg="6">
              <ShippingDetails
                details={addressData.shipping}
                shippingTitle={true}
                onChange={(name, value) => handleShippingChange(name, value)}
              />
            </Col>
          </Row>
          <div className="GeneralForm__input">
            <input value="Përditëso të dhënat" type="submit" />
          </div>
        </Form>
      </UserSettings>
    </div>
  );
};

export default Address;
