import React from "react";
import UserSettings from "../../components/UserSettings/UserSettings";

import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Order from "./Order/Order";
import NavigationButton from "../../components/Buttons/NavigationButton/NavigationButton";
import UserSettingsTitle from "../../components/UserSettings/UserSettingsTitle/UserSettingsTitle";

import "./Orders.scss";

const USER_ORDERS = gql`
  query UserOrders {
    customer {
      orders(first: 100) {
        nodes {
          id
          date
          total
          status
          orderId
          orderNumber
          dateCompleted
          customer {
            id
            firstName
          }
        }
      }
    }
  }
`;

const Orders = () => {
  const { loading, error, data } = useQuery(USER_ORDERS);

  if (loading) {
    return (
      <div className="Orders">
        <UserSettings>
          <UserSettingsTitle title="Porositë" />
          <div className="Orders__container">
            <p>Duke u ngarkuar... </p>
          </div>
        </UserSettings>
      </div>
    );
  }

  if (error) {
    return (
      <div className="Orders">
        <UserSettings>
          <UserSettingsTitle title="Porositë" />
          <div className="Orders__container">
            <p>{error.message}</p>
          </div>
        </UserSettings>
      </div>
    );
  }

  if (data && data.customer.orders.nodes.length === 0) {
    return (
      <div className="Orders Orders--none">
        <UserSettings>
          <UserSettingsTitle title="Porositë" />
          <p className="Orders__message">
            Ju momentalisht nuk keni ndonjë porosi!
          </p>
          <NavigationButton to="/" title="Shto produkte" />
        </UserSettings>
      </div>
    );
  }

  return (
    <div className="Orders">
      <UserSettings>
        <UserSettingsTitle title="Porositë" />
        <div className="Orders__container">
          {data.customer.orders.nodes.map((order) => {
            return <Order key={order.id} {...order} />;
          })}
        </div>
      </UserSettings>
    </div>
  );
};

export default Orders;
