import React from "react";
import Slider from "react-slick";
import { NavLink, Link } from "react-router-dom";
import prevArrow from "../../assets/icons/prev-arrow-white.svg";
import nextArrow from "../../assets/icons/next-arrow-white.svg";

import "./SectionSlider.scss";

const SliderItemContent = (props) => {
  if (props.link.url && props.link.openInNewTab) {
    return (
      <a target="_blank" href={props.link.url} rel="noopener noreferrer">
        <img alt="Begmart Promotion" src={props.image} />
      </a>
    );
  }

  if (props.link.url) {
    return (
      <NavLink to={props.link.url}>
        <img alt="Begmart Promotion" src={props.image} />
      </NavLink>
    );
  }

  return <img alt="Begmart Promotion" src={props.image} />;
};

export const SectionSlider = (props) => {
  const leftArrow = () => {
    return <img src={prevArrow} />;
  };

  const rightArrow = () => {
    return <img src={nextArrow} />;
  };

  const settings = {
    swipe: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: rightArrow(),
    prevArrow: leftArrow(),
  };

  return (
    <div className="SectionSlider">
      <Slider {...settings}>
        {props.data.map((item, index) => {
          return (
            <div
              key={`${index}-${item.image?.mediaItemUrl}-slider`}
              className="SectionSlider__item"
            >
              <div className="slider-container">
                <SliderItemContent
                  link={item.link}
                  image={item.image?.mediaItemUrl}
                />
              </div>
              <div className="slider-container-mb">
                <SliderItemContent
                  link={item.link}
                  image={item.mobileImage?.mediaItemUrl}
                />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
