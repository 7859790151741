import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { SideCart } from "../../components/SideCart/SideCart";
import { Container, Form } from "reactstrap";
import CheckoutData from "./CheckoutData/CheckoutData";
import PaymentData from "./PaymentData/PaymentData";
import DeliveryData from "./DeliveryData/DeliveryData";
import CheckoutTotalSubmit from "./CheckoutTotalSubmit/CheckoutTotalSubmit";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { localUser } from "../../assets/Helpers";
import { useMutation, useQuery } from "@apollo/react-hooks";
import v4 from "uuid/v4";
import { useDispatch } from "react-redux";
import { addUserInfo } from "../../actions/userActions";
import loginIcon from "../../assets/icons/login-icon-red.svg";
import ArrowSignIn from "../../assets/icons/arrow-right-signIn.svg";
import { CHECKOUT_MUTATION } from "../../queries/mutations/checkoutMutation";
import {
  USER_BILLING_DATA,
  USER_SHIPPING_DATA,
} from "../../queries/checkoutQuery";

import "./Checkout.scss";

const Checkout = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const userInfoFromStorage = localUser();

  const {
    data: dataBilling,
    error: errorBilling,
    loading: loadingBilling,
  } = useQuery(USER_BILLING_DATA, {
    variables: {
      id: userInfo.id,
    },
  });

  const {
    loading: loadingShipping,
    error: errorShipping,
    data: dataShipping,
  } = useQuery(USER_SHIPPING_DATA, {
    variables: {
      id: userInfo.id,
    },
  });

  const [checkoutData, setCheckoutData] = useState({
      billing: {
        lastName: "",
        company: "",
        zone: "",
        address: "",
        telephone: "",
        state: "",
        city: "",
        country: "XK",
        confirm_password: "",
        fiscal_number: "",
        isLoaded: false,
      },
      shipping: {
        firstName: "",
        lastName: "",
        company: "",
        zone: "",
        address: "",
        state: "",
        city: "",
        country: "XK",
        differentShipping: false,
        isLoaded: false,
      },
      comment: "",
      deliverytime: "",
      payment: {
        method: "",
      },
      transport: {
        method: "",
        deliveryTime: "",
      },
      isValid: false,
      pos_payment_type: "",
      isOrderUpdated: false,
      shippingAttributes: {
        text: "Falas",
        cost: "0",
      },
      formBankData: null,
      birthday: null,
    }),
    [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    console.log("transport", checkoutData.transport.deliveryTime);
  });

  const [
    checkout,
    { data: mutationData, error: mutationError, loading: mutationLoading },
  ] = useMutation(CHECKOUT_MUTATION);

  if (mutationError) {
    return (
      <div className="Checkout">
        <Container>
          <div className="card-totals">
            <p>
              {mutationError
                .toString()
                .includes(
                  "An account is already registered with your email address. Please log in."
                ) ? (
                <span>
                  Një përdorues është i regjistruar tashmë me këtë email. Ju
                  lutem kyquni!
                </span>
              ) : (
                <span>Kemi probleme me website, provoni perseri!</span>
              )}
            </p>
            <div>
              {mutationError
                .toString()
                .includes(
                  "An account is already registered with your email address. Please log in."
                ) ? (
                <div className="login-button">
                  <img src={loginIcon} alt="" />
                  <NavLink to="/kyçu" className="login-button__title">
                    KYÇU
                  </NavLink>
                </div>
              ) : (
                <NavLink to="/" className="CartButtons__button">
                  KTHEHU PRAPA
                </NavLink>
              )}
            </div>
          </div>
        </Container>
      </div>
    );
  }

  const handleKeyChange = (name, value) => {
    if (value) {
      setCheckoutData({
        ...checkoutData,
        [name]: value,
      });
    } else {
      setCheckoutData({
        ...checkoutData,
        [name]: "",
      });
    }
  };

  const handleSubmitForm = () => {};

  if (
    mutationData &&
    mutationData.checkout.result &&
    !checkoutData.formBankData
  ) {
    if (checkoutData.payment.method === "procredit") {
      setCheckoutData({
        ...checkoutData,
        formBankData: mutationData.checkout.redirect,
      });
      return (window.location.href = mutationData.checkout.redirect);
    } else {
      props.history.push("/porosia-sukses/1");
    }
  }

  const handleBillingChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      billing: {
        ...checkoutData.billing,
        [name]: value,
      },
    });
  };

  const handleTransportChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      transport: {
        ...checkoutData.transport,
        [name]: value,
      },
    });
  };

  const handleShippingChange = (name, value) => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        [name]: value,
      },
    });
  };

  const handlePaymentChange = (value) => {
    setCheckoutData({
      ...checkoutData,
      payment: {
        ...checkoutData.payment,
        method: value,
      },
    });
  };

  const handlePaymentTypeChange = (value) => {
    setCheckoutData({
      ...checkoutData,
      pos_payment_type: value,
    });
  };

  // Checkout Submit form
  const handleCheckoutSubmit = async (e) => {
    e.preventDefault();

    const {
      billing,
      shipping,
      payment,
      comment,
      transport,
      pos_payment_type,
    } = checkoutData;

    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        firstName: billing.firstName,
        lastName: billing.lastName,
        company: billing.company,
        zone: billing.city,
        city: billing.city,
        telephone: billing.phone,
      },
    });

    if (!validFields()) {
      setHasErrors(true);
      window.scrollTo(0, 0);
      return;
    }

    if (!userInfoFromStorage) {
      try {
        await checkout({
          variables: {
            inputData: {
              clientMutationId: v4(),
              account: {
                username: billing.email,
                password: billing.password,
              },
              billing: {
                firstName: billing.firstName,
                lastName: billing.lastName,
                phone: billing.telephone,
                address1: billing.address,
                country: "XK",
                state: billing.state,
                city: billing.city,
                email: billing.email,
                postcode: "",
                company: billing.company ? billing.company : "",
                address2: billing.fiscal_number,
              },
              metaData: [
                { key: "pos_payment_type", value: pos_payment_type },
                { key: "delivery", value: transport.deliveryTime },
                { key: "order_note", value: checkoutData.comment },
                { key: "birthday", value: checkoutData.birthday },
              ],
              paymentMethod: payment.method,
              shippingMethod: transport.method,
            },
          },
        });
      } catch (error) {}
    } else {
      try {
        await checkout({
          variables: {
            inputData: {
              clientMutationId: v4(),
              billing: {
                firstName: billing.firstName,
                lastName: billing.lastName,
                phone: billing.telephone,
                address1: billing.address,
                country: "XK",
                state: billing.state,
                city: billing.city,
                email: billing.email,
                postcode: "10000",
                company: billing.company ? billing.company : "",
                address2: billing.fiscal_number,
              },
              metaData: [
                { key: "pos_payment_type", value: pos_payment_type },
                { key: "delivery", value: transport.deliveryTime },
                { key: "order_note", value: checkoutData.comment },
              ],
              paymentMethod: payment.method,
              shippingMethod: transport.method,
            },
          },
        });
      } catch (error) {}
    }
  };

  if (!checkoutData.billing.isLoading && dataBilling) {
    const billingInfo = dataBilling.customer.billing;
    if (billingInfo) {
      setCheckoutData({
        ...checkoutData,
        billing: {
          ...checkoutData.billing,
          firstName: billingInfo.firstName,
          lastName: billingInfo.lastName,
          company: billingInfo.company,
          zone: billingInfo.city,
          city: billingInfo.city,
          country: billingInfo.country,
          address: billingInfo.address1,
          apartment: billingInfo.address2,
          telephone: billingInfo.phone,
          email: billingInfo.email,
          fiscal_number: billingInfo.address2,
          isLoading: true,
        },
      });
    }
  }

  if (!checkoutData.shipping.isLoading && dataShipping) {
    const shippingInfo = dataShipping.customer.shipping;
    if (shippingInfo) {
      setCheckoutData({
        ...checkoutData,
        shipping: {
          ...checkoutData.shipping,
          firstName: shippingInfo.firstName,
          lastName: shippingInfo.lastName,
          company: shippingInfo.company,
          zone: shippingInfo.city,
          city: shippingInfo.city,
          address: shippingInfo.address1,
          apartment: shippingInfo.address2,
          telephone: shippingInfo.phone,
          email: shippingInfo.email,
          isLoading: true,
        },
      });
    }
  }

  const validField = (input) => {
    return input !== "";
  };

  const validFields = () => {
    const { billing, shipping, transport } = checkoutData;

    const validBillingFields =
      validField(billing.firstName) &&
      validField(billing.lastName) &&
      validField(billing.zone) &&
      validField(billing.address) &&
      validField(billing.telephone);

    const validShippingFields =
      validField(shipping.firstName) &&
      validField(shipping.lastName) &&
      validField(shipping.zone) &&
      validField(shipping.address) &&
      validField(shipping.telephone);

    if (shipping.differentShipping) {
      return validBillingFields && validShippingFields;
    }

    return validBillingFields;
  };

  if (mutationData && mutationData.checkout.customer.jwtAuthToken) {
    const userInfo = {
      ID: mutationData.checkout.customer.id,
      AUTHTOKEN: mutationData.checkout.customer.jwtAuthToken,
      REFRESH_TOKEN: mutationData.checkout.customer.jwtRefreshToken,
      userId: mutationData.checkout.customer.customerId,
      firstName: mutationData.checkout.customer.firstName,
      lastName: mutationData.checkout.customer.lastName,
    };

    localStorage.setItem("BEGROSS_USER", JSON.stringify(userInfo));

    setTimeout(() => {
      dispatch(
        addUserInfo({
          id: userInfo.ID,
          isLogged: true,
          firstTimeRegistered: true,
          firstName: checkoutData.billing.firstName,
          lastName: checkoutData.billing.lastName,
          userId: mutationData.checkout.customer.customerId,
        })
      );
    }, 1000);
  }

  return (
    <div className="Checkout">
      <Container>
        <div className="row mt-40">
          <div className="col col-md-12 col-lg-9 col-xl-9 col-12 ">
            <Form
              onSubmit={(e) => {
                handleCheckoutSubmit(e);
              }}
            >
              <div className="top-section">
                <div className="row">
                  <div className="col">
                    <h2 className="page-title">Bëje porosinë</h2>
                    {!userInfo.isLogged && (
                      <div
                        className="mt-25"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="title-2">
                          Plotëso të dhënat për faturim{" "}
                          <span className="or_span"> ose </span>
                        </span>

                        <NavLink
                          to="/kyçu/?toCart=true"
                          className="signIn_button"
                        >
                          <img src={ArrowSignIn} alt="" />
                          Kyçu
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>

                {userInfoFromStorage && (loadingBilling || loadingShipping) ? (
                  <div>
                    <div className="loader mt-40" />
                  </div>
                ) : (
                  <CheckoutData
                    birthday={(date) =>
                      setCheckoutData({
                        ...checkoutData,
                        birthday: date,
                      })
                    }
                    user={userInfoFromStorage}
                    details={checkoutData.billing}
                    shippingDetails={checkoutData.shipping}
                    onChange={(name, value) => handleBillingChange(name, value)}
                    onShippingChange={(name, value) =>
                      handleShippingChange(name, value)
                    }
                  />
                )}
                <PaymentData
                  onChange={(value) => handlePaymentChange(value)}
                  onChangeType={(value) => handlePaymentTypeChange(value)}
                />
              </div>
              <div className="buttons-section">
                <div className="row">
                  <div className="col col-md-6 col-12 ">
                    <DeliveryData
                      city={checkoutData.billing.city}
                      comment={checkoutData.comment}
                      onCommentChange={(value) =>
                        handleKeyChange("comment", value)
                      }
                      onDeliveryTimeChange={(value) => {
                        handleTransportChange("deliveryTime", value);
                      }}
                    />
                  </div>
                  <div className="col col-md-6 col-12">
                    <CheckoutTotalSubmit
                      loading={mutationLoading}
                      submitForm={handleSubmitForm}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <div className="col col-md-12 col-lg-3 col-xl-3 col-12 side-cart-container">
            <SideCart fromCheckout={true} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(Checkout);
