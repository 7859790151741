import React from "react";

import ContentLoader from "react-content-loader";

const ProductLoader = () => (
  <div className="ProductLoader">
    <ContentLoader
      height={400}
      width={240}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="48" y="48" rx="0" ry="0" width="153" height="93" />
      <rect x="188" y="7" rx="0" ry="0" width="46" height="29" />
      <rect x="110" y="170" rx="0" ry="0" width="41" height="23" />
      <rect x="42" y="211" rx="0" ry="0" width="175" height="20" />
      <rect x="80" y="240" rx="0" ry="0" width="100" height="20" />
      <rect x="31" y="320" rx="0" ry="0" width="190" height="42" />
    </ContentLoader>
  </div>
);

export default ProductLoader;
