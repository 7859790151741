import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_PRODUCTS_MENU } from "../../../queries/categoriesQuery";
import { useProductsContext } from "../../../contexts/ProductsContext/ProductsContext";

import ArrowLeft from "../../../assets/icons/arrow-left-2.png";

import "./CategoriesMobile.scss";

const CategoriesMobile = (props) => {
  const { data, error, loading } = useQuery(GET_PRODUCTS_MENU);
  const [showCategories, setShowCategories] = useState(true);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const ProductsCTX = useProductsContext();
  const activeCategoryId = ProductsCTX.activeCategoryId;
  const selectedCategoryName = ProductsCTX.selectedCategoryName;

  if (error) {
    return <div> Error </div>;
  }

  if (loading) {
    return <div></div>;
  }

  const categoryItems = data.menus.nodes[0].menuItems.nodes;

  const subCategoryItems = categoryItems
    .filter((item) => {
      return item.childItems.nodes.length > 0;
    })
    .map((item) => {
      return {
        id: item.connectedObject.productCategoryId,
        list: item.childItems.nodes,
      };
    });

  const filteredSubCategoryItems = subCategoryItems?.filter((item) => {
    return item.id === activeCategoryId;
  });

  const handleCategoryChange = (id, categoryName) => {
    const subCategories = subCategoryItems.filter((item) => {
      return item.id === id;
    });

    if (subCategories.length > 0) {
      ProductsCTX.handleCategoryChange(id, categoryName);
      setShowCategories(!showCategories);
      setShowSubCategories(true);
    } else {
      props.isOpen();
      setShowSubCategories(false);
      ProductsCTX.filterProductsWithCategory(id, categoryName);
    }
  };

  const handleSubCategoryChange = (id) => {
    ProductsCTX.handleSubCategoryChange(id);
    props.isOpen();
    setShowSubCategories(false);
    setShowCategories(true);
  };

  return (
    <div className="CategoriesMobile">
      {showCategories && (
        <>
          <div className="nav-cat ">Kategorite</div>
          <ul className="CategoriesMobile__categories">
            {categoryItems.map((item) => {
              let categoryId = item.connectedObject.productCategoryId;
              let categoryName = item.label;
              const iconImage = item.connectedObject.image?.mediaItemUrl;

              return (
                <div
                  className="CategoriesMobile__category-item"
                  onClick={() => handleCategoryChange(categoryId, categoryName)}
                >
                  <img
                    className="CategoriesMobile__icon"
                    src={iconImage}
                    alt=""
                  />
                  <div className="CategoriesMobile__text">
                    {item.label.replace("&amp;", "&")}
                  </div>
                  <span className="CategoriesMobile__plus-icon"> + </span>
                </div>
              );
            })}
          </ul>
        </>
      )}
      {showSubCategories && (
        <ul className="CategoriesMobile__sub-categories">
          <div
            onClick={() => {
              setShowSubCategories(!showSubCategories);
              setShowCategories(!showCategories);
            }}
            className="CategoriesMobile__category-name"
          >
            <img className="CategoriesMobile__arrow" src={ArrowLeft} alt="" />
            <p className="CategoriesMobile__sub-text">
              {selectedCategoryName.replace("&amp;", "")}
            </p>
          </div>
          {filteredSubCategoryItems[0]?.list?.map((item) => {
            const id = item.connectedObject.slug;
            return (
              <div
                onClick={() => handleSubCategoryChange(id)}
                className="CategoriesMobile__sub-category-item"
              >
                {item.label.replace("&amp;", "")}
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CategoriesMobile;
