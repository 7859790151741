import React, { useEffect, useCallback,useContext } from "react";
import closeIcon from "../../assets/icons/closeIcon4.svg";
import {GlobalContext} from "../../contexts/GlobalContext";


import "./StockMessageModal.scss";

const StockMessageModal = (props) => {
  const globalContext = useContext(GlobalContext);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      globalContext.closeQuantityModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (props?.isOpen) {
    return (
      <div
        className={
          props.isOpen ? "StockMessageModal active" : "StockMessageModal"
        }
      >
        <p className="StockMessageModal__message-text">
          <span>E rëndësishme</span>: Numri i produkteve të kërkuara në porosinë
          tuaj nuk është i disponueshëm
        </p>
        <img
          src={closeIcon}
          className="close-modal-button"
          onClick={() => globalContext.closeQuantityModal()}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default StockMessageModal;
