import React, { useContext } from "react";

const CartContextValues = {
  loading: false,
  updateLoading: false,
  addLoading: false,
  error: undefined,
  total: "",
  subtotal: "",
  items: undefined,
  isEmpty: undefined,
  localCart: undefined,
  refetch: () => {},
  addProduct: () => {},
  emptyCart: () => {},
  removeItemFromCart: () => {},
  updateProductFromLocalCart: () => {},
  addProductToLocalCart: () => {},
  handleLoadMore: () => {},
  getProductQuantity: () => {},
  getProductPrices: () => {
    return { regular: "", sale: "" };
  },
  updateProductQuantity: () => {},
};

export const CartContext = React.createContext(CartContextValues);

export const useCartContext = () => useContext(CartContext);
