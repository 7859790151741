import React, { useState } from "react";
import closeicon from "../../../assets/icons/closeIcon3.svg";
import addIcon2 from "../../../assets/icons/addIcon2.svg";
import addIcon from "../../../assets/icons/addIcon1.svg";
import { useCartContext } from "../../../lib/CartContext/CartContext";

import "./SideCartItem.scss";

export const SideCartItem = (props) => {
  const cartContext = useCartContext();
  const { product, cartKey, quantity, key } = props;
  const [itemQuantity, setItemQuantity] = useState(quantity);
  let discountPrice = product.salePrice;
  let regularPrice = product.regularPrice.replace("&nbsp;€", "");
  const maxQuantity = product.stockQuantity;

  const handleQuantityUpdate = (increment = true) => {
    if (increment && itemQuantity !== maxQuantity) {
      setItemQuantity((prev) => prev + 1);
      cartContext.updateProductFromCart(cartKey, itemQuantity + 1);
      // cartContext.updateProductFromLocalCart(
      //   product.productId,
      //   itemQuantity + 1
      // );
    }

    if (!increment && itemQuantity > 1) {
      setItemQuantity((prev) => prev - 1);
      cartContext.updateProductFromCart(cartKey, itemQuantity - 1);
      // cartContext.updateProductFromLocalCart(
      //   product.productId,
      //   itemQuantity - 1
      // );
    }
  };

  const handleDeleteItem = () => {
    cartContext.updateProductFromCart(cartKey, 0);
    // cartContext.removeItemFromCart(product.productId);
  };

  const calculatedPrice = (quantity, price) => {
    let priceToCalculate = price.replace("&nbsp;€", "");

    let value = Number(priceToCalculate);

    const finalValue = value * quantity;

    let res = priceToCalculate.split(".");

    if (res.length === 1 || res[1].length < 3) {
      value = value.toFixed(2);
    }

    return finalValue.toFixed(2);
  };

  return (
    <div className="product">
      <div className="product-column">
        <div className="product-wrapper">
          <div className="close-wrapper ">
            <img onClick={handleDeleteItem} src={closeicon} alt="" />
          </div>
          <div className="product-image">
            <img src={product.image?.sourceUrl} alt="" />
          </div>
          <div className="details">
            <span>{product.name.replace("&amp;", "&")}</span>
          </div>
        </div>
      </div>
      <div className="quantity-column">
        <div className="quantity">
          <div className="quantity-info ">
            <span className="qty">X{quantity}</span>&nbsp;
            <span className="">Cope</span>
          </div>
          <div className="quantity-buttons ">
            <a className="addIconn" onClick={() => handleQuantityUpdate(false)}>
              <img src={addIcon2} alt="" />
            </a>
            <a className="addIconn" onClick={() => handleQuantityUpdate()}>
              <img src={addIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="price-column">
        <div className="price-wrapper">
          {regularPrice && discountPrice ? (
            <div className="price">
              <div className="discount-price">
                <p>{calculatedPrice(quantity, regularPrice)}</p>
              </div>
              <p className="regular-price">
                {calculatedPrice(quantity, discountPrice)}
              </p>
            </div>
          ) : (
            <div className="price">
              <p className="regular-price">
                {calculatedPrice(quantity, regularPrice)}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
